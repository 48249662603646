import { z } from 'zod'
import type { TenantCreationFormValues } from './types'

export const initialFormValues: TenantCreationFormValues = {
  company: '',
  is_in_europe: 'true',
  accept_terms: false,
}

export const validationSchema = z.object({
  company: z.string().min(1),
  accept_terms: z.boolean().refine((value) => value),
  is_in_europe: z.string(),
})
