import { IconBuildingWarehouse } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Drawer } from '~/components/UI/Drawer'

type InventoryBalancingDetailProps = {
  isOpen: boolean
  onClose: () => void
}

export const InventoryBalancingDetail = ({ isOpen, onClose }: InventoryBalancingDetailProps) => {
  const { t } = useTranslation(['work-area'])

  const mockData = [
    {
      CodigoBodega: 'BOC123',
      Bodega: 'QUITO ALMIRANTE PADILLA',
      Faltante: 100,
    },
    {
      CodigoBodega: 'ATRR122',
      Bodega: 'BARRANQUILLA CALLE 72',
      Faltante: 110,
    },
    {
      CodigoBodega: 'OUI346',
      Bodega: 'QUITO ALMIRANTE PADILLA',
      Faltante: 100,
    },
    {
      CodigoBodega: 'BOC123',
      Bodega: 'BARRANQUILLA CALLE 72',
      Faltante: 100,
    },
    {
      CodigoBodega: 'ATRR122',
      Bodega: 'QUITO ALMIRANTE PADILLA',
      Faltante: 110,
    },
    {
      CodigoBodega: 'BOC123',
      Bodega: 'BARRANQUILLA CALLE 72',
      Faltante: 100,
    },
    {
      CodigoBodega: 'ATRR122',
      Bodega: 'QUITO ALMIRANTE PADILLA',
      Faltante: 110,
    },
  ]

  return (
    <Drawer isOpen={isOpen} onClose={onClose} className="min-w-[800px]">
      <div className="flex h-full">
        <div className="relative w-[800px] flex-shrink overflow-auto pt-10">
          <div className="mb-24 px-5 lg:px-14">
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <IconBuildingWarehouse size={24} className="text-azure" />
                  <h3>{t('work-area:inventoryBalancing.distributionCenter')}</h3>
                </div>
                <h3 className="text-body-2 ml-9">BODEGA CENTRAL #1</h3>
              </div>
              <div className="">
                <table className="min-w-full divide-y divide-night-100">
                  <thead className="bg-lightblue">
                    <tr>
                      <th className="text-caption-1 px-4 py-3 text-left">
                        {t('work-area:inventoryBalancing.table.warehouseCode')}
                      </th>
                      <th className="text-caption-1 px-4 py-3 text-left">
                        {t('work-area:inventoryBalancing.table.warehouseName')}
                      </th>
                      <th className="text-caption-1 px-4 py-3 text-left">
                        {t('work-area:inventoryBalancing.table.missing')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-night-100 border-b border-night-100 bg-white">
                    {mockData.map((item, index) => (
                      <tr key={index}>
                        <td className="text-caption-1 px-4 py-3">{item.CodigoBodega}</td>
                        <td className="text-caption-1 px-4 py-3">{item.Bodega}</td>
                        <td className="text-caption-2 px-4 py-3 text-right">{item.Faltante}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
