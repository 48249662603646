import i18n from 'i18next'
import LanguageDetector, { type DetectorOptions } from 'i18next-browser-languagedetector'
import Backend, { type HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const httpBackendOptions: HttpBackendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json', // path to load resources
}

const lookupLocalStorage = 'lang'

const detectorOptions: DetectorOptions = {
  order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
  // keys or params to lookup language from
  lookupQuerystring: lookupLocalStorage,
  lookupLocalStorage,
  // cache user language on
  caches: ['localStorage'],
  convertDetectedLanguage: (lng) => {
    // get only the language code, we strip the country code
    // e.g. 'en-US' -> 'en'
    const lngCode = lng.slice(0, 2)
    return lngCode
  },
}

export function getLanguageFromLocalStorage() {
  return localStorage.getItem(lookupLocalStorage) || fallbackLng
}

export function isSpanishLocale() {
  return getLanguageFromLocalStorage() === 'es'
}

const languages = ['es', 'en'] as const
const namespaces = ['common'] as const

const fallbackLng = languages[0]
export const defaultNS = namespaces[0]

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLng,
    defaultNS: defaultNS,
    backend: httpBackendOptions,
    detection: detectorOptions,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
