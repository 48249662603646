// Import the auto generated route tree
import { createRouter } from '@tanstack/react-router'
import { ErrorScreenNotFound } from '~/components/ErrorScreen'
import { routeTree } from '../routeTree.gen'
import { customParser, customStringifier } from '../utils/routing'
import { queryClient } from './queryClient'

export const router = createRouter({
  routeTree,
  context: {
    auth: null!, // This will be set after we wrap the app in the AuthProvider
    queryClient,
  },
  //https://github.com/TanStack/router/issues/1534 - defaultPreload is not working as expected with nested routes. Disabled for now
  defaultPreload: false,
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  stringifySearch: customStringifier,
  parseSearch: customParser,
  defaultNotFoundComponent: ErrorScreenNotFound,
})

export type Router = typeof router

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: Router
  }
}
