import { IconButton } from '@mui/material'
import type { GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { KnowledgeBaseTooltipIcon } from '~/components/UI/KnowledgeBaseTooltipIcon'
import type { DocsPageProps } from '~/components/UI/KnowledgeBaseTooltipIcon/types'

interface CustomHeaderProps extends GridColumnHeaderParams, DocsPageProps {
  headerName: string
}

export const ColumnHeaderWithKnowledgeBaseIconButton = (props: CustomHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [butonsContainer, setButonsContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    const el = ref.current

    if (!el) return

    let ancestorEl = el.parentElement?.parentElement

    if (!ancestorEl) {
      return
    }

    const querySelector = ' .MuiDataGrid-iconButtonContainer'
    let iconButtonContainerEl = ancestorEl.querySelector(querySelector)

    if (!iconButtonContainerEl && ancestorEl.parentElement) {
      // on columns with aggregation, the icon button container is in the parent element
      ancestorEl = ancestorEl.parentElement
      iconButtonContainerEl = ancestorEl.querySelector(querySelector)
    }

    if (!iconButtonContainerEl) {
      return
    }

    ancestorEl.classList.add('group')
    setButonsContainer(iconButtonContainerEl as HTMLDivElement)
  }, [])

  return (
    <>
      <div ref={ref} title={props.headerName}>
        {props.headerName}
      </div>

      {butonsContainer &&
        createPortal(
          <IconButton size="small" className="!hidden group-hover:!block">
            <KnowledgeBaseTooltipIcon
              concept={props.headerName}
              pageContentId={props.pageContentId}
              pageUrl={props.pageUrl}
              iconProps={{ size: 18 }}
            />
          </IconButton>,
          butonsContainer,
        )}
    </>
  )
}
