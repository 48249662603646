import { IconLoader2 } from '@tabler/icons-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import type { LoaderProps } from './types'

export const Loader = (props: LoaderProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        props.fullHeight && 'h-full',
        props.className,
      )}
      title={t('status.loading') + '...'}
    >
      <IconLoader2 size={props.iconSize || 30} className="animate-spin text-azure" />
    </div>
  )
}
