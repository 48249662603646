import clsx from 'clsx'
import type { ContainerWithClassName } from '~/types/shared'

interface PageContentToolbarProps extends ContainerWithClassName {}

const ROOT_ID = 'page-content-toolbar'

export const PageContentToolbar = (props: PageContentToolbarProps) => {
  return (
    <div id={ROOT_ID} className={clsx('flex flex-wrap items-center gap-4', props.className)}>
      {props.children}
    </div>
  )
}

PageContentToolbar.ROOT_ID = ROOT_ID
