import { neutral as twNeutral, transparent as twTransparent } from 'tailwindcss/colors'

export const colors = {
  neutral: twNeutral, // TODO: replace later, it is the last color we need to replace from tailwindcss
  transparent: twTransparent,
  white: '#ffffff',
  azure: {
    DEFAULT: '#087eff',
    dark: '#0468d4',
  },
  raisinblack: '#1b1e30',
  spacecadet: '#3e3c5a',
  argentinianblue: '#64aeff',
  glaucous: '#6e8cc8',
  ghostwhite: '#f5f8ff',
  lightblue: '#ebf4ff',
  darkblue: '#0559b4',
  columbiablue: '#b9daff',
  whitesmoke: '#f4f5f7',
  seasalt: '#f8f9fa',

  backdrop: '#9F9D9D', // TODO: check later, not in the DS

  night: {
    DEFAULT: '#161616',
    50: '#f5f5f5',
    100: '#e7e7e7',
    200: '#c4c4c5',
    300: '#a1a1a2',
    400: '#88888a',
    500: '#767677',
    600: '#5c5c5d',
    700: '#454545',
    800: '#2d2e2d',
  },

  majorelleblue: {
    DEFAULT: '#7a50f0',
    50: '#f2edfe',
    100: '#e3ddfd',
    200: '#d7cbfc',
    300: '#cab9fa',
    400: '#bca7f8',
    500: '#af97f6',
    600: '#a284f5',
    700: '#5630c2',
    800: '#3a1f87',
  },

  royalblue: {
    DEFAULT: '#232a71',
    50: '#e9eaf1',
    100: '#d3d4e2',
    200: '#bdbfd4',
    300: '#a6aac6',
    400: '#9294b8',
    500: '#7b7faa',
    600: '#656a9b',
    700: '#161b51',
    800: '#060b3c',
  },

  emerald: {
    DEFAULT: '#34bd6d',
    50: '#eaf8f1',
    100: '#d6f2e2',
    200: '#c1ead4',
    300: '#afe4c5',
    400: '#99deb6',
    500: '#85d7a6',
    600: '#70d09a',
    700: '#279e59',
    800: '#1d7040',
  },

  orangepeel: {
    DEFAULT: '#ffa337',
    50: '#fff6eb',
    100: '#ffecd7',
    200: '#ffe3c3',
    300: '#ffdab0',
    400: '#ffd19b',
    500: '#ffc887',
    600: '#ffbf73',
    700: '#e29234',
    800: '#bb721b', // TODO: verify this color with the design system
  },

  bittersweet: {
    DEFAULT: '#ff554e',
    50: '#ffedee',
    100: '#ffdedc',
    200: '#ffccca',
    300: '#febcb9',
    400: '#ffa9a7',
    500: '#ff9995',
    600: '#ff8783',
    700: '#be2f2b',
    800: '#961713',
  },
} as const
