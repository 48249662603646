import type { SxProps, Theme } from '@mui/material'
import {
  type StaticDateRangePickerProps,
  dateRangeCalendarClasses,
  dateRangePickerDayClasses,
  dayCalendarClasses,
  pickersDayClasses,
  pickersMonthClasses,
} from '@mui/x-date-pickers-pro'
import { addWeeks, isSameDay, subDays, subWeeks } from 'date-fns'
import { colors } from '~/theme/colors'
import { formatStandardDate } from '~/utils/date'
import type { GetDefaultDateRangeParams } from './types'

const today = new Date()

export const DEFAULT_MAIN_DATE_RANGE: [Date, Date] = [subWeeks(today, 1), addWeeks(today, 1)]

export const getDefaultDateRange = (
  dateRange: [Date, Date] = DEFAULT_MAIN_DATE_RANGE,
): GetDefaultDateRangeParams => {
  const [initialDate, endDate] = dateRange

  // If the initial date and the end date are the same (example: if the range start on 1st of month and end day is equal), the range will be 30 days
  if (isSameDay(initialDate, endDate)) {
    const thirtyDaysAgo = subDays(initialDate, 30)

    return {
      value: [thirtyDaysAgo, endDate],
      processedValue: [formatStandardDate(thirtyDaysAgo), formatStandardDate(endDate)],
    }
  }

  return {
    processedValue: [formatStandardDate(initialDate), formatStandardDate(endDate)],
    value: [initialDate, endDate],
  }
}

export const monthPickerStyles: SxProps<Theme> = {
  [`.${pickersMonthClasses.monthButton}.Mui-selected`]: {
    backgroundColor: colors['azure'],
  },
}

export const dateRangePickerFilterSlotProps: StaticDateRangePickerProps<Date>['slotProps'] = {
  layout: {
    sx: {
      [`.${dateRangeCalendarClasses.monthContainer}`]: {
        borderRight: 'none !important',
      },
      [`.${dayCalendarClasses.monthContainer}`]: {
        '& *': {
          borderRadius: '0px !important',
        },
        margin: '0 1rem',
        borderRadius: '0.75rem',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
        border: '1px solid #e5e5e5',
        [`.${pickersDayClasses.root}.Mui-selected`]: {
          backgroundColor: colors['azure'],
        },
        // #region styles to round the corners of weeks
        [`.${dayCalendarClasses.weekContainer}:first-of-type`]: {
          [`.${dateRangePickerDayClasses.root}:first-of-type`]: {
            borderTopLeftRadius: '0.75rem !important',
            [`.${dateRangePickerDayClasses.dayOutsideRangeInterval}:first-of-type:hover`]: {
              borderTopLeftRadius: '0.75rem !important',
            },
            [`.${pickersDayClasses.root}.Mui-selected`]: {
              borderTopLeftRadius: '0.75rem !important',
            },
          },
          [`.${dateRangePickerDayClasses.root}:last-of-type`]: {
            borderTopRightRadius: '0.75rem !important',
            [`.${dateRangePickerDayClasses.dayOutsideRangeInterval}:last-of-type:hover`]: {
              borderTopRightRadius: '0.75rem !important',
            },
            [`.${pickersDayClasses.root}.Mui-selected`]: {
              borderTopRightRadius: '0.75rem !important',
            },
          },
        },
        [`.${dayCalendarClasses.weekContainer}:last-of-type`]: {
          [`.${dateRangePickerDayClasses.root}:first-of-type`]: {
            borderBottomLeftRadius: '0.75rem !important',
            [`.${dateRangePickerDayClasses.dayOutsideRangeInterval}:first-of-type:hover`]: {
              borderBottomLeftRadius: '0.75rem !important',
            },
            [`.${pickersDayClasses.root}.Mui-selected`]: {
              borderBottomLeftRadius: '0.75rem !important',
            },
          },
          [`.${dateRangePickerDayClasses.root}:last-of-type`]: {
            borderBottomRightRadius: '0.75rem !important',
            [`.${dateRangePickerDayClasses.dayOutsideRangeInterval}:last-of-type:hover`]: {
              borderBottomRightRadius: '0.75rem !important',
            },
            [`.${pickersDayClasses.root}.Mui-selected`]: {
              borderBottomRightRadius: '0.75rem !important',
            },
          },
        },
        // #endregion
      },
      [`.${dayCalendarClasses.weekContainer}`]: {
        flex: 1,
        flexGrow: 1,
        margin: 0,
        [`.${dateRangePickerDayClasses.outsideCurrentMonth}`]: {
          backgroundColor: 'white !important',
        },
      },
      [`.${dateRangePickerDayClasses.root}`]: {
        flex: 1,
      },
      [`.${dateRangePickerDayClasses.rangeIntervalPreview}:last-of-type`]: {
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.day}:last-of-type`]: {
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.day}:first-of-type`]: {
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.rangeIntervalDayHighlight}:first-of-type`]: {
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.rangeIntervalDayHighlight}:last-of-type`]: {
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.rangeIntervalDayHighlight}`]: {
        backgroundColor: colors['lightblue'],
      },
      //TODO: delete border in range
      [`.${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
        backgroundColor: '#f3f4f6',
        border: '1px solid #f3f4f6',
        borderRadius: '0px !important',
      },
      [`.${dateRangePickerDayClasses.hiddenDayFiller}`]: {
        [`.${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
          backgroundColor: 'white',
          border: 'none',
        },
      },
    },
  },

  calendarHeader: {
    slotProps: {
      nextIconButton: {
        className: '!rounded-md !shadow',
        disableTouchRipple: true,
      },
      previousIconButton: {
        className: '!rounded-md !shadow',
        disableTouchRipple: true,
      },
    },
  },
}
