import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { GridColumnsPanel, GridToolbarContainer, useGridRootProps } from '@mui/x-data-grid-premium'
import { IconChevronDown, IconFileDownload } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '~/theme/colors'
import { omit } from '~/utils/general'
import { Button } from '../Button'
import { DataTableExportDialog } from '../DataTableExportDialog'
import type { DataTableToolbarProps } from './types'

export const DataTableToolbar = (props: DataTableToolbarProps) => {
  const { t } = useTranslation()

  const rootProps = useGridRootProps()

  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [columnsPanelAnchor, setColumnsPanelAnchor] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(columnsPanelAnchor)
  const id = open ? 'data-table-columns-button-popper' : undefined

  const handleClickAway = () => {
    setColumnsPanelAnchor(null)
  }

  const gridToolbarContainerProps = omit(
    props,
    'hideExportDialogColumnsSection',
    'hideExportDialogDateRangeSection',
    'exportButtonProps',
    'extraContent',
  )

  return (
    <GridToolbarContainer
      {...gridToolbarContainerProps}
      className={clsx(props.className, 'items-end !gap-6 bg-white !py-4')}
    >
      <Button
        variant="secondary"
        startIcon={IconFileDownload}
        onClick={() => {
          setExportDialogOpen(true)
        }}
        {...props.exportButtonProps}
      >
        {t('action.export')}
      </Button>

      {props.extraContent && (
        <div className="flex flex-grow items-center">{props.extraContent}</div>
      )}

      {exportDialogOpen && (
        <DataTableExportDialog
          onCancel={() => setExportDialogOpen(false)}
          modalProps={{ open: true }}
          hideExportDialogColumnsSection={props.hideExportDialogColumnsSection}
          hideExportDialogDateRangeSection={props.hideExportDialogDateRangeSection}
        />
      )}

      {!rootProps.disableColumnSelector && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="ms-auto">
            <Button
              variant="secondary"
              endIcon={IconChevronDown}
              onClick={(event) => {
                setColumnsPanelAnchor(
                  columnsPanelAnchor ? null : (event.currentTarget as HTMLButtonElement),
                )
              }}
            >
              {t('columns')}
            </Button>

            <BasePopup
              id={id}
              open={open}
              anchor={columnsPanelAnchor}
              placement="bottom-end"
              disablePortal
              className="z-10 mt-1 bg-white shadow-popup"
            >
              {/* TODO: We need to find another way to style this component */}
              <GridColumnsPanel
                className="max-h-[280px]"
                sx={{
                  '& .MuiTypography-root': {
                    color: colors['night']['700'],
                    fontFamily: '"Inter Tight", sans-serif',
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    fontWeight: '400',
                  },
                }}
              />
            </BasePopup>
          </div>
        </ClickAwayListener>
      )}
    </GridToolbarContainer>
  )
}
