import clsx from 'clsx/lite'
import type { PropsWithChildren } from 'react'
import type { OptionalClassName } from '~/types'

export type ModalFooterProps = PropsWithChildren & OptionalClassName

export const ModalFooter = (props: ModalFooterProps) => {
  return (
    <div
      className={clsx(
        'border-t border-t-night-100 px-5 pb-6 pt-4 md:pe-6 md:ps-10',
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
