const TENANT_CURRENCY = 'tenant-currency'

export const saveTenantCurrencyInLocalStorage = (tenantCurrency = 'COP') => {
  localStorage.setItem(TENANT_CURRENCY, tenantCurrency)
}

export const getTenantCurrencyFromLocalStorage = () => {
  const tenantCurrency = localStorage.getItem(TENANT_CURRENCY)

  if (!tenantCurrency) {
    return 'COP'
  }

  return tenantCurrency
}
