export const boxShadows = {
  topbarUserButton:
    '0px 0px 1px 0px rgba(34, 43, 129, 0.31),  0px 4px 8px 0px rgba(34, 43, 129, 0.15)',
  popup: '0px 4px 11px 0px rgba(23, 29, 64, 0.15)',
  // for form fields (input, select, etc...) and some buttons for now
  field: '0px 1px 3px 0px rgba(26, 26, 26, 0.08), 0px 0.5px 0px 0px rgba(26, 26, 26, 0.08)',
  card: '0px 4px 7px 0px rgba(24, 16, 46, 0.05)',
  'filters-set':
    '0px 0.8174806833267212px 2.452442169189453px 0px rgba(26, 26, 26, 0.08), 0px 0.4087403416633606px 0px 0px rgba(26, 26, 26, 0.08)',
  tooltip: '0px 8px 16px -2px rgba(27, 33, 44, 0.12)',
  'card-variant-2': '0px 7px 12px 0px rgba(59, 58, 99, 0.04)',
} as const
