import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from '~/components/UI/Toast'
import { api } from '~/lib/api'
import { queryClient } from '~/lib/queryClient'
import { FILTERS_SETS, FILTERS_SETS_CREATE } from '~/lib/queryKeys'
import type {
  ApiResponse,
  FilersSet,
  FiltersSetCreate,
  FiltersSetCreateResponse,
} from '~/types/apiContracts'

const createFiltersSet = async (filtersSet: FiltersSetCreate, moduleId: string) => {
  const res = (await api
    .post(`filters/user_filters/${moduleId}`, {
      json: filtersSet,
    })
    // TODO: update the response type when the API is ready
    .json()) as ApiResponse<FiltersSetCreateResponse>

  return res.data
}

export const useFiltersSetCreateMutation = (moduleId: string) => {
  const { t } = useTranslation()

  return useMutation({
    mutationKey: [FILTERS_SETS_CREATE],
    mutationFn: (filtersSet: FiltersSetCreate) => {
      return createFiltersSet(filtersSet, moduleId)
    },
    onSuccess: (_, variables) => {
      toast({ status: 'success', content: t('statusMessage.savedChangesSuccess') })

      queryClient.setQueryData([FILTERS_SETS, moduleId], (old: { filters: FilersSet[] }) => {
        return old?.filters
          ? {
              filters: [
                {
                  app_name: moduleId,
                  name: variables.name,
                  filter_string: variables.filter_string,
                },
                ...old.filters,
              ],
            }
          : old
      })

      // invalidate the data after 5s, the API is taking time to update the data
      setTimeout(() => queryClient.invalidateQueries({ queryKey: [FILTERS_SETS, moduleId] }), 5000)
    },
  })
}
