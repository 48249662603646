import { useQuery } from '@tanstack/react-query'
import { api, stringifyQueryParams } from '~/lib/api'
import { CAMPAIGNS } from '~/lib/queryKeys'
import type { ApiResponse, CampaignsResponse } from '~/types/apiContracts'
import type { ModuleDataCommonParams } from '~/types/shared'
import { parseModuleDataCommonParams } from '~/utils/misc'

interface GetCampaingsParams extends ModuleDataCommonParams {
  selectedWarehouse?: string
}

const getCampaigns = async (params: GetCampaingsParams) => {
  const searchParams = stringifyQueryParams({
    ...parseModuleDataCommonParams(params),
  })

  const response = (await api
    .get('inventory/promotional_campaigns/', {
      searchParams,
    })
    .json()) as ApiResponse<CampaignsResponse>

  return response.data
}

export const useCampaignsQuery = (params: GetCampaingsParams) => {
  return useQuery({
    queryKey: [CAMPAIGNS, params],
    queryFn: () => getCampaigns(params),
  })
}
