import clsx from 'clsx'
import type { NotionRichTextResponse } from '~/types/apiContracts'

type RichTextContentProps = {
  richText: NotionRichTextResponse[]
}

export const RichTextContent = (props: RichTextContentProps) => {
  return (
    <p>
      {props.richText.map((text, idx) => {
        if (text.type !== 'text') return null // we only want text

        const isLink = !!text.text.link

        const Tag = isLink ? 'a' : 'span'

        return (
          <Tag
            key={idx}
            className={clsx(
              text.annotations.bold && 'font-bold',
              text.annotations.italic && 'italic',
              text.annotations.strikethrough && 'line-through',
              text.annotations.underline && 'underline',
              text.annotations.code && 'font-mono',
            )}
          >
            {text.text.content}
          </Tag>
        )
      })}
    </p>
  )
}
