import { useField } from 'formik'
import { Autocomplete } from '../Autocomplete/Autocomplete'
import type { AutocompleteControlledProps } from './types'

export const AutocompleteControlled = (props: AutocompleteControlledProps) => {
  const [{ value }, , { setValue }] = useField(props.name)

  return (
    <Autocomplete
      {...props}
      onChange={(_, newValue) => setValue(newValue)}
      value={props.multiple ? value || [] : value}
    />
  )
}
