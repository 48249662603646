import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { TENANTS_LIST } from '~/lib/queryKeys'
import type { ApiResponse, TenantsListResponse } from '~/types/apiContracts'

async function fetchTenants() {
  const res = (await api.get('tenants/tenants/').json()) as ApiResponse<TenantsListResponse>

  return res.data
}

export function useTenantsQuery(enabled: boolean) {
  return useQuery({
    queryKey: [TENANTS_LIST],
    queryFn: () => fetchTenants(),
    enabled,
  })
}
