import type { IconProps } from '@tabler/icons-react'
import { IconX } from '@tabler/icons-react'
import type { ButtonHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '../IconButton'

export type ModalCloseButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Pick<IconProps, 'size'>

export const ModalCloseButton = ({ size = 20, ...props }: ModalCloseButtonProps) => {
  const { t } = useTranslation()

  return (
    <IconButton title={t('closeModal')} {...props}>
      <IconX size={size} />
    </IconButton>
  )
}
