import type { MenuItemProps } from '@mui/base/MenuItem'
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem'
import clsx from 'clsx/lite'
import { forwardRef } from 'react'

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(function MenuItem(props, ref) {
  const { className, ...other } = props
  return (
    <BaseMenuItem
      ref={ref}
      className={clsx(
        'flex items-center p-3',
        props.onClick && 'select-none focus:bg-night-100 focus:text-night-800 focus:outline-0',
        props.disabled ? 'text-night-300' : 'cursor-pointer hover:bg-seasalt',
        className,
      )}
      {...other}
    />
  )
})
