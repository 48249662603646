import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getDateRangeStr } from '~/utils/date'
import { RadioButtonControlled } from '../RadioButtonControlled'
import { DataTableExportDialogSection } from './DataTableExportDialogSection'
import type { DataTableExportDialogDateRanges } from './types'

export const DataTableExportDialogSectionDateRange = () => {
  const { t, i18n } = useTranslation()

  const dateRanges = useMemo(() => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
    const seventhDayBeforeToday = new Date(today)
    seventhDayBeforeToday.setDate(today.getDate() - 6)

    const todayStr = today.toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' })
    const yesterdayStr = yesterday.toLocaleDateString(i18n.language, {
      month: 'short',
      day: 'numeric',
    })
    const currentMonthRangeStr = getDateRangeStr(firstDayOfMonth, today, i18n.language)
    const lastMonthRangeStr = getDateRangeStr(
      firstDayOfLastMonth,
      lastDayOfLastMonth,
      i18n.language,
    )
    const last7DaysDateRangeStr = getDateRangeStr(seventhDayBeforeToday, today, i18n.language)

    return [
      { value: 'today', label: t('dates.today'), details: todayStr },
      { value: 'yesterday', label: t('dates.yesterday'), details: yesterdayStr },
      { value: 'currentMonth', label: t('dates.currentMonth'), details: currentMonthRangeStr },
      { value: 'last7Days', label: t('dates.last7Days'), details: last7DaysDateRangeStr },
      { value: 'lastMonth', label: t('dates.lastMonth'), details: lastMonthRangeStr },
      { value: 'all', label: t('all') },
      { value: 'custom', label: t('custom') },
    ] satisfies DataTableExportDialogDateRanges
  }, [i18n.language, t])

  const id = 'dataTableExportDialogDateRange'

  return (
    <DataTableExportDialogSection title={t('dates.dateRange')} titleId={id}>
      <div role="group" aria-labelledby={id} className="grid grid-cols-4 gap-2 @container">
        {dateRanges.map((dateRange) => {
          return (
            <div key={dateRange.value} className="flex items-center gap-2">
              <RadioButtonControlled
                name="dateRange"
                value={dateRange.value}
                label={dateRange.label}
              />
              {dateRange.details && (
                <div className="text-body-2 ms-7 text-night-600 @sm:ms-0">{dateRange.details}</div>
              )}
            </div>
          )
        })}
      </div>
    </DataTableExportDialogSection>
  )
}
