import { useEffect, useRef } from 'react'
import { PageTopbarRoot } from '~/components/PageTopbar/PageTopbarRoot'
import { PageContentToolbar } from '../PageContent/PageContentToolbar'

export const useDataTableScrollAnimation = (): void => {
  const virtualScrollerRef = useRef<HTMLElement | null>(null)
  const dataTableGrid = useRef<HTMLElement | null>(null)
  const topContainerGrid = useRef<HTMLElement | null>(null)
  const pageTopbar = useRef<HTMLElement | null>(null)
  const pageContentToolbar = useRef<HTMLElement | null>(null)
  const estimateMaxClientHeight = useRef(0)

  const initialStyles = useRef<{
    contentToolbarHeight: number
    topbarHeight: number
    paddingTop: number
    paddingBottom: number
    display: string
  }>({
    contentToolbarHeight: 0,
    topbarHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    display: '',
  })

  const isAnimationApplied = useRef(false)

  const VALUE_GAP = 12 // TODO: This value should be obtained from the DOM
  const MARGIN_SEPARATOR_TOPBAR_TO_TOOLBAR = 1 // TODO: This value should be obtained from the DOM

  useEffect(() => {
    const findElements = () => {
      virtualScrollerRef.current = document.querySelector('.MuiDataGrid-virtualScroller')
      dataTableGrid.current = document.querySelector('.MuiDataGrid-main')
      topContainerGrid.current = document.querySelector('.MuiDataGrid-topContainer')
      pageTopbar.current = document.getElementById(PageTopbarRoot.ROOT_ID)
      pageContentToolbar.current = document.getElementById(PageContentToolbar.ROOT_ID)

      if (!virtualScrollerRef.current || !dataTableGrid.current || !topContainerGrid.current) {
        setTimeout(findElements, 100)
      } else if (pageTopbar.current || pageContentToolbar.current) {
        initialStyles.current = {
          contentToolbarHeight: pageContentToolbar.current?.clientHeight || 0,
          topbarHeight: pageTopbar.current?.clientHeight || 0,
          paddingTop: pageTopbar.current
            ? parseFloat(window.getComputedStyle(pageTopbar.current).paddingTop)
            : 0,
          paddingBottom: pageTopbar.current
            ? parseFloat(window.getComputedStyle(pageTopbar.current).paddingBottom)
            : 0,
          display: pageContentToolbar.current
            ? window.getComputedStyle(pageContentToolbar.current).display
            : '',
        }

        if (estimateMaxClientHeight.current === 0) {
          const pinnedRowsElement = dataTableGrid.current.querySelector('.MuiDataGrid-pinnedRows')
          let pinnedRowsHeight = 0
          if (pinnedRowsElement) {
            const pinnedRowChildren = Array.from(pinnedRowsElement.children) as HTMLElement[]
            pinnedRowsHeight = pinnedRowChildren.reduce((acc, child) => acc + child.clientHeight, 0)
          }
          estimateMaxClientHeight.current =
            dataTableGrid.current.clientHeight +
            (pageTopbar.current?.clientHeight || 0) +
            (pageContentToolbar.current?.clientHeight || 0) +
            VALUE_GAP +
            MARGIN_SEPARATOR_TOPBAR_TO_TOOLBAR -
            (topContainerGrid.current.clientHeight + pinnedRowsHeight)
        }

        virtualScrollerRef.current.addEventListener('scroll', handleScroll)
      }
    }

    const handleScroll = () => {
      if (!virtualScrollerRef.current) return

      const scrollTop = virtualScrollerRef.current.scrollTop
      const maxScroll = 10
      const fadeOutRange = 5

      const scrollRatio =
        scrollTop > maxScroll ? Math.min((scrollTop - maxScroll) / fadeOutRange, 1) : 0

      const scrollClientHeight = document.querySelector(
        '.MuiDataGrid-scrollbarContent',
      )?.clientHeight

      window.requestAnimationFrame(() => {
        if (
          scrollClientHeight !== undefined &&
          estimateMaxClientHeight.current !== 0 &&
          scrollClientHeight >= estimateMaxClientHeight.current
        ) {
          const opacity = 1 - scrollRatio

          if (!isAnimationApplied.current) {
            isAnimationApplied.current = true

            if (pageContentToolbar.current) {
              pageContentToolbar.current.style.transition = 'height 0.2s ease, opacity 0.2s ease'
            }
            if (pageTopbar.current) {
              pageTopbar.current.style.transition =
                'height 0.2s ease, opacity 0.2s ease, padding-top 0.2s ease, padding-bottom 0.2s ease'
            }
          }

          if (pageTopbar.current) {
            const newTopbarHeight = initialStyles.current.topbarHeight * (1 - scrollRatio)
            const newPaddingTop = initialStyles.current.paddingTop * (1 - scrollRatio)
            const newPaddingBottom = initialStyles.current.paddingBottom * (1 - scrollRatio)

            pageTopbar.current.style.height = `${newTopbarHeight}px`
            pageTopbar.current.style.paddingTop = `${newPaddingTop}px`
            pageTopbar.current.style.paddingBottom = `${newPaddingBottom}px`
            pageTopbar.current.style.opacity = `${opacity}`
          }

          if (pageContentToolbar.current) {
            const newContentToolbarHeight =
              initialStyles.current.contentToolbarHeight * (1 - scrollRatio)

            pageContentToolbar.current.style.height = `${newContentToolbarHeight}px`
            pageContentToolbar.current.style.opacity = `${opacity}`
          }

          if (scrollTop > maxScroll) {
            if (pageContentToolbar.current) {
              pageContentToolbar.current.style.display = 'none'
              pageContentToolbar.current.classList.add('sticky', 'top-0')
            }
            if (pageTopbar.current) {
              pageTopbar.current.classList.add('relative', 'top-0')
            }
          }
        }

        if (scrollTop <= maxScroll && isAnimationApplied.current) {
          isAnimationApplied.current = false

          if (pageTopbar.current) {
            pageTopbar.current.style.height = `${initialStyles.current.topbarHeight}px`
            pageTopbar.current.style.paddingTop = `${initialStyles.current.paddingTop}px`
            pageTopbar.current.style.paddingBottom = `${initialStyles.current.paddingBottom}px`
            pageTopbar.current.style.opacity = '1'
            pageTopbar.current.classList.remove('relative', 'top-0')
          }

          if (pageContentToolbar.current) {
            pageContentToolbar.current.style.height = `${initialStyles.current.contentToolbarHeight}px`
            pageContentToolbar.current.style.opacity = '1'
            pageContentToolbar.current.style.display = initialStyles.current.display
            pageContentToolbar.current.classList.remove('sticky', 'top-0')
          }
        }
      })
    }

    findElements()

    return () => {
      if (virtualScrollerRef.current && (pageTopbar.current || pageContentToolbar.current)) {
        virtualScrollerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
}
