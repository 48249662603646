import { GridActionsCellItem, type GridActionsCellItemProps } from '@mui/x-data-grid-premium'
import type { LinkProps } from '@tanstack/react-router'
import { createLink } from '@tanstack/react-router'
import { forwardRef } from 'react'

type GridActionsCellItemLinkProps = GridActionsCellItemProps & LinkProps

const GridActionsCellItemWrapper = forwardRef<HTMLDivElement, GridActionsCellItemLinkProps>(
  function GridActionsCellItemWrapper(props, ref) {
    return (
      <GridActionsCellItem
        {...props}
        disabled={!!props['aria-disabled']}
        ref={ref}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      />
    )
  },
)

export const GridActionsCellItemLink = createLink(GridActionsCellItemWrapper)
