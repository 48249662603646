import type { GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useRef } from 'react'
import { isFooterRow } from '~/utils/dataGrid'

interface CustomEditableCellProps extends GridRenderCellParams {}

export const CustomEditableCell = (params: CustomEditableCellProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isFooterRow(params.row)) {
      return
    }

    const cell = ref.current

    if (!cell || !cell.parentElement) {
      return
    }

    const parent = cell.parentElement
    const parentRole = parent.getAttribute('role')

    // just additional check, might not be needed
    if (!parentRole || parentRole !== 'gridcell') {
      return
    }

    const handleFocus = () => {
      params.api.startCellEditMode({
        field: params.field,
        id: params.id,
      })
    }

    parent.addEventListener('focus', handleFocus)

    return () => {
      parent.removeEventListener('focus', handleFocus)
    }
  }, [params])

  return <div ref={ref}>{params.value}</div>
}
