import { Dropdown } from '@mui/base'
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { isEqual } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { Menu } from '../UI/Menu'
import { useDateRangePickerFilterContext } from './DateRangePickerFilterProvider'
import type { DatePeriod } from './types'

export const DateRangePickerFilterPeriods = () => {
  const today = startOfDay(new Date())

  const { values, savePeriodSelected } = useDateRangePickerFilterContext()

  const { t } = useTranslation()

  const periods: DatePeriod[] = [
    {
      label: t('dates.currentYear'),
      getValue: () => {
        return [startOfYear(today), today]
      },
    },
    {
      label: t('dates.last365Days'),
      getValue: () => {
        const oneYearAgo = subYears(today, 1)
        return [oneYearAgo, today]
      },
    },
    {
      label: t('dates.currentMonth'),
      getValue: () => {
        return [startOfMonth(startOfDay(today)), today]
      },
    },
    {
      label: t('dates.currentWeek'),
      getValue: () => {
        return [startOfWeek(today), today]
      },
    },
    {
      label: t('dates.lastYear'),
      getValue: () => {
        const oneYearAgo = subYears(today, 1)
        return [startOfYear(oneYearAgo), endOfYear(oneYearAgo)]
      },
    },
    {
      label: t('dates.lastMonth'),
      getValue: () => {
        const oneMonthAgo = subMonths(today, 1)
        return [startOfMonth(oneMonthAgo), endOfMonth(oneMonthAgo)]
      },
    },
    {
      label: t('dates.lastWeek'),
      getValue: () => {
        const lastWeek = subWeeks(today, 1)
        return [startOfWeek(lastWeek), endOfWeek(lastWeek)]
      },
    },
    {
      label: t('dates.yesterday'),
      getValue: () => {
        const yesterday = subDays(today, 1)
        return [yesterday, today]
      },
    },
    {
      label: t('dates.today'),
      getValue: () => {
        return [today, today]
      },
    },
  ]

  const dateSelectedLabel = periods.find((period) => {
    const date = period.getValue()
    return isEqual(date, values.main)
  })?.label

  return (
    <Dropdown>
      <Menu.Button className="flex items-center gap-4 border border-night-200 bg-white px-4">
        <span> {dateSelectedLabel || t('dates.customPeriod')}</span>
      </Menu.Button>
      <Menu>
        {periods.map((period, index) => (
          <Menu.Item key={index} onClick={() => savePeriodSelected(period.getValue())}>
            <span className="w-full text-center">{period.label}</span>
          </Menu.Item>
        ))}
      </Menu>
    </Dropdown>
  )
}
