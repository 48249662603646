import { useTranslation } from 'react-i18next'
import { ErrorScreen } from './ErrorScreen'
import { NotFoundSvg } from './NotFoundSvg'

export const ErrorScreenNotFound = () => {
  const { t } = useTranslation()

  return (
    <ErrorScreen
      image={<NotFoundSvg />}
      title={t('notFound404')}
      subtitle={t('notFound404Message')}
    />
  )
}
