import { isFunction } from 'lodash-es'

/**
 * Maps an array to an object using the provided mapping function or value.
 *
 * @template T - The type of the array elements.
 * @template R - The type of the object values.
 * @param array - The array to be mapped.
 * @param mapFnOrValue - The mapping function or value.
 */
export function mapArrayToObject<T extends string | number, R>(
  array: T[],
  mapFnOrValue: ((value: T) => R) | R,
): Record<T, R> {
  return array.reduce(
    (acc, item) => {
      acc[item] = isFunction(mapFnOrValue) ? mapFnOrValue(item) : mapFnOrValue
      return acc
    },
    {} as Record<T, R>,
  )
}

/**
 * Swaps the elements at the specified indices in an array. It doesn't mutate the original array.
 * @param arr The array to swap elements in.
 * @param oldIndex The index of the element to be swapped.
 * @param newIndex The index to swap the element with.
 * @returns A new array with the elements swapped, or the original array if the indices are out of bounds.
 */
export function swapArrayElements<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
  // Check if indices are within bounds
  if (oldIndex < 0 || newIndex < 0 || oldIndex >= arr.length || newIndex >= arr.length) {
    return arr
  }

  const newArr = [...arr]
  const temp = newArr[oldIndex]
  newArr[oldIndex] = newArr[newIndex]
  newArr[newIndex] = temp

  return newArr
}

/**
 * Moves an element within an array from one index to another.
 *
 * @param {T[]} arr The array to modify.
 * @param {number} oldIndex The index of the element to move.
 * @param {number} newIndex The index to move the element to.
 * @returns A new array with the element moved to the new index, or the original array if the indices are out of bounds.
 */
export function moveArrayElement<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
  // Check if indices are within bounds
  if (
    oldIndex < 0 ||
    newIndex < 0 ||
    oldIndex >= arr.length ||
    newIndex > arr.length ||
    oldIndex === newIndex
  ) {
    return arr
  }

  const newArr = [...arr]
  // Remove the element from the old position
  const [element] = newArr.splice(oldIndex, 1)
  // Insert the element at the new position
  newArr.splice(newIndex, 0, element)

  return newArr
}

function countItems(arr: string[]) {
  return arr.reduce<Record<string, number>>((acc, item) => {
    acc[item] = (acc[item] || 0) + 1
    return acc
  }, {})
}

export function arraysHaveSameItems(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) {
    return false
  }

  const count1 = countItems(arr1)
  const count2 = countItems(arr2)

  return Object.keys(count1).every((key) => count1[key] === count2[key])
}
