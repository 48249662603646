import { IconDatabaseOff } from '@tabler/icons-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import type { NoDataProps } from './types'
import { NoDataVariants } from './types'

export const NoData = ({ variant = NoDataVariants.VARIANT_1 }: NoDataProps) => {
  const { t } = useTranslation()

  const variantClass = variant === NoDataVariants.VARIANT_1 ? 'bg-seasalt' : 'bg-white'

  return (
    <div className="flex h-full items-center justify-center px-6 py-4">
      <div className={clsx('flex h-16 items-center gap-4 rounded-[10px] px-4', variantClass)}>
        <IconDatabaseOff stroke={1.5} size={32} />
        <h2 className="text-subtitle-1">{t('statusMessage.noDataAvailable')}</h2>
      </div>
    </div>
  )
}
