import { useTranslation } from 'react-i18next'
import type { StockStatus } from '~/types/apiContracts'
import { Chip } from '../UI/Chip'
import type { ChipProps } from '../UI/Chip/Chip'
import type { StockStatusBadgeProps } from './types'

export const StockStatusChip = (props: StockStatusBadgeProps) => {
  const { t } = useTranslation()

  const stockStatusColor: Record<StockStatus, ChipProps['color']> = {
    'OUT OF STOCK (P0)': 'red',
    'CRITICAL STOCK (P1)': 'red',
    'LOW STOCK (P2)': 'yellow',
    'NORMAL STOCK (P3)': 'green',
    'NON-MOVING STOCK': 'purple',
    'NO STOCK AND NO SALES': 'purple',
    'INSUFFICIENT DATA': 'default',
    'UNAVAILABLE STATUS': 'default',
  } as const

  return (
    <Chip
      size="small"
      color={stockStatusColor[props.stockStatusCode] || 'default'}
      label={t(`stockStatus.${props.stockStatusCode}`, {
        defaultValue: props.stockStatusCode,
      })}
    />
  )
}
