import type { GridActionsColDef } from '@mui/x-data-grid-premium'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '~/components/UI/DataTable'
import { GridActionsCellItemLink } from '~/components/UI/GridActionsCellItemLink'
import { ViewDetailsLabel } from '~/components/ViewDetailsLabel/ViewDetailsLabel'
import { useCampaignsQuery } from '~/pages/Planning/PromotionalCampaigns/PromotionalCampaignsPage/hooks/useCampaignsQuery'
import type { Campaign } from '~/types/apiContracts'
import { usePaginationModel } from '~/utils/dataGrid'
import { getColumns } from './helpers'
import type { InventoryBalancingTableProps } from './types'

export const InventoryBalancingTable = ({ onView }: InventoryBalancingTableProps) => {
  const { paginationModel, setPaginationModel } = usePaginationModel(25)

  const campaignsQuery = useCampaignsQuery({ paginationModel })

  const { t } = useTranslation(['planning', 'common'])

  const total = campaignsQuery.data?.total || 0
  const rows = campaignsQuery.data?.items || []

  const apiColumns = campaignsQuery.data?.columns

  const getActions: GridActionsColDef<Campaign>['getActions'] = useCallback(() => {
    return [
      <GridActionsCellItemLink
        key="viewMoreDetails"
        icon={<ViewDetailsLabel />}
        label={t('common:action.viewMoreDetails')}
        title={t('common:action.viewMoreDetails')}
        onClick={() => onView()}
      />,
    ]
  }, [t, onView])

  const columns = useMemo(() => getColumns({ getActions, apiColumns }), [getActions, apiColumns])

  return (
    <DataTable
      columns={columns}
      tableId="campaigns"
      rowCount={total}
      rows={rows}
      paginationMode="server"
      pagination
      loading={campaignsQuery.isFetching}
      isLoadingFirstTime={campaignsQuery.isLoading}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      tableContainerClassName="h-[700px]"
      initialState={{
        pinnedColumns: {
          right: ['actions'],
        },
      }}
    />
  )
}
