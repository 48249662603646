import { createTheme } from '@mui/material'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { boxShadows } from './boxShadows'
import { colors } from './colors'

export const muiTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          paddingInline: '14px',
        },
        listbox: {
          paddingBlock: 0,
        },
        inputRoot: {
          paddingBlock: '8px',
          paddingInlineStart: '12px',
          fontSize: '12px',
        },
        input: {
          padding: '0 !important',
          height: 'auto !important',
        },
      },
      defaultProps: {
        clearIcon: <IconX size={16} />,
        popupIcon: <IconChevronDown size={16} />,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'transparent',
          color: colors.night[300],
          '&.Mui-selected': {
            borderColor: colors.night[100],
            color: colors.night[800],
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: colors.ghostwhite,
            },
          },
          // hover state
          '&:hover': {
            backgroundColor: colors.ghostwhite,
          },
        },
        page: {
          minWidth: '28px',
          height: '28px',
          borderRadius: '10px',
          // TODO: set font size

          '&.Mui-disabled': {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
        },
        previousNext: {
          minWidth: '36px',
          height: '36px',
          borderRadius: '12px',
          boxShadow: '0px 1px 2px 0px #1a1a1a14',

          '&.Mui-disabled': {
            borderColor: 'transparent',
            boxShadow: 'none',
            backgroundColor: colors.night[50],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: colors.azure.DEFAULT,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.night[800],
          boxShadow: boxShadows.tooltip,
          color: colors.white,
          padding: '12px',
          borderRadius: '8px',
          // #region text-caption-1 styles
          fontFamily: '"Inter Tight", sans-serif',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: '1rem',
          // #endregion
        },
        arrow: {
          color: colors.night[800],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
        },
        input: {
          paddingBlock: '10.5px',
        },
      },
    },
  },
})
