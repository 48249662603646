import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useFiltersSetCreateMutation } from '~/hooks/mutations/useFiltersSetCreateMutation'
import { validateFormValues } from '~/utils/formik'
import { Button } from '../../UI/Button'
import { InputControlled } from '../../UI/InputControlled'
import { RadioButtonControlled } from '../../UI/RadioButtonControlled'
import { initialValues, validationSchema } from './helpers'
import type { FiltersSetFormProps, FiltersSetFormValues } from './types'

export const FiltersSetForm = (props: FiltersSetFormProps) => {
  const { t } = useTranslation()

  const createFiltersSetMutation = useFiltersSetCreateMutation(props.moduleId)

  const formik = useFormik<FiltersSetFormValues>({
    initialValues,
    validate: (values) => validateFormValues(values, validationSchema),
    onSubmit: async (values, { resetForm }) => {
      // TODO: pending final implementation
      await createFiltersSetMutation.mutateAsync(
        {
          name: values.name,
          filter_string: JSON.stringify(props.filters),
          is_private: values.saveFor === 'all',
        },
        {
          onSuccess: () => {
            resetForm()
            props.onSubmitSuccess()
          },
        },
      )
    },
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="divide-y divide-night-100">
          <div className="flex flex-wrap items-center gap-x-[76px] gap-y-6 px-10 py-6">
            <InputControlled
              name="name"
              placeholder={t('filterName')}
              className="me-[76px] min-w-[278px]"
              disabled={formik.isSubmitting}
              size="large"
            />
            <div className="flex gap-9">
              <RadioButtonControlled
                name="saveFor"
                value="justMe"
                label={t('saveJustForMe')}
                disabled={formik.isSubmitting}
              />
              <RadioButtonControlled
                name="saveFor"
                value="all"
                label={t('saveForEveryone')}
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
          <div className="flex justify-end gap-6 px-8 pb-6 pt-4">
            <Button variant="primary-text" onClick={props.onCancel} size="large">
              {t('action.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
              isLoading={formik.isSubmitting}
              size="large"
            >
              {t('saveFilters')}
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}
