import { type VariantProps, cva } from 'class-variance-authority'
import { pick } from 'lodash-es'

const chipClasses = cva('inline-flex items-center justify-center rounded-full', {
  variants: {
    color: {
      default: 'bg-night-100 text-night-600',
      red: 'bg-bittersweet-50 text-bittersweet',
      yellow: 'bg-orangepeel-50 text-orangepeel',
      green: 'bg-emerald-50 text-emerald',
      purple: 'bg-majorelleblue-50 text-majorelleblue',
    },
    size: { small: 'px-2 h-4 text-caption-1', medium: 'px-3 h-6' },
  },
  defaultVariants: {
    color: 'default',
    size: 'small',
  },
})

export type ChipProps = VariantProps<typeof chipClasses> & {
  label?: string
}

export const Chip = (props: ChipProps) => {
  const cvaProps = pick(props, ['color', 'size'])

  return (
    <div className={chipClasses(cvaProps)}>
      {/* TODO: typography pending */}
      <span className="truncate">{props.label}</span>
    </div>
  )
}
