import { IconCalendarMonth, IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { startOfDay, startOfMonth, subYears } from 'date-fns'
import { useRef, useState } from 'react'
import { useEvent } from 'react-use'
import { formatShortDate } from '~/utils/date'
import { Badge } from '../UI/Badge'
import { DateRangePickerFilterComparison } from './DateRangePickerFilterComparison'
import { DateRangePickerFilterMain } from './DateRangePickerFilterMain'
import { DateRangePickerFilterPeriods } from './DateRangePickerFilterPeriods'
import { DateRangePickerFilterProvider } from './DateRangePickerFilterProvider'
import type { DateRangePickerFilterProps } from './types'

const today = startOfDay(new Date())

const DEFAULT_COMPARISON_DATE_RANGE: [Date, Date] = [
  subYears(startOfMonth(today), 1),
  subYears(today, 1),
]

export const DateRangePickerFilter = ({
  allowCompare = false,
  defaultMainDateRange,
  defaultComparisonDateRange = DEFAULT_COMPARISON_DATE_RANGE,
  showShortcutMenu = false,
  moduleId,
  disabled,
  ...props
}: DateRangePickerFilterProps) => {
  const [calendarAnchor, setCalendarAnchor] = useState<null | HTMLElement>(null)
  const [comparisonCalendarcAnchor, setComparisonCalendarAnchor] = useState<null | HTMLElement>(
    null,
  )

  const popupRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClickOutside = (event: Event) => {
    if (comparisonCalendarcAnchor) return

    if (
      popupRef.current &&
      !popupRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setCalendarAnchor(null)
    }
  }

  useEvent('mousedown', handleClickOutside)

  const handleOpenCalendar = (event: React.MouseEvent<HTMLElement>) => {
    setCalendarAnchor(calendarAnchor ? null : event.currentTarget)
  }

  const handleOpenComparisonCalendar = () => {
    setComparisonCalendarAnchor(calendarAnchor)
  }

  const isOpenCalendar = !!calendarAnchor
  const calendarId = isOpenCalendar ? 'calendar-popup' : undefined
  const calendarComparisonId = isOpenCalendar ? 'calendar-comparison-popup' : undefined

  return (
    <DateRangePickerFilterProvider
      moduleId={moduleId}
      defaultValues={{ main: defaultMainDateRange, comparison: defaultComparisonDateRange }}
    >
      {({ values, hasSettedDates }) => {
        const formattedSelectedMainDates = values.main
          .map((date) => formatShortDate(date as Date))
          .join(' - ')

        return (
          <div className="flex flex-col items-start gap-2">
            <div className="mx-auto flex h-11 gap-4">
              <Badge className="flex flex-1" color="primary" invisible={!hasSettedDates}>
                <button
                  type="button"
                  className="flex w-full items-center justify-center gap-3 rounded-xl border border-night-200 bg-white px-4 text-sm transition-all duration-200 disabled:border-none disabled:bg-neutral-50 disabled:text-neutral-200"
                  onClick={handleOpenCalendar}
                  ref={buttonRef}
                  disabled={disabled}
                >
                  <IconCalendarMonth size={20} />
                  <span>{formattedSelectedMainDates}</span>
                  <IconChevronDown
                    className={clsx('transition-all duration-200', isOpenCalendar && 'rotate-180')}
                  />
                </button>
              </Badge>

              {showShortcutMenu && <DateRangePickerFilterPeriods />}
            </div>

            <DateRangePickerFilterMain
              ref={popupRef}
              allowCompare={allowCompare}
              isOpen={isOpenCalendar}
              onCompareClick={handleOpenComparisonCalendar}
              onSave={() => setCalendarAnchor(null)}
              popupAnchor={calendarAnchor}
              popupId={calendarId}
              {...props}
            />

            {allowCompare && (
              <DateRangePickerFilterComparison
                isOpen={!!comparisonCalendarcAnchor}
                onBackClick={() => setComparisonCalendarAnchor(null)}
                onSave={() => {
                  setComparisonCalendarAnchor(null)
                  setCalendarAnchor(null)
                }}
                popupId={calendarComparisonId}
                popupAnchor={comparisonCalendarcAnchor}
                {...props}
              />
            )}
          </div>
        )
      }}
    </DateRangePickerFilterProvider>
  )
}
