import { useTranslation } from 'react-i18next'
import { RadioButtonControlled } from '../RadioButtonControlled'
import { DataTableExportDialogSection } from './DataTableExportDialogSection'
import type { DataTableExportDialogRows } from './types'

export const DataTableExportDialogSectionRows = () => {
  const { t } = useTranslation()

  const id = 'dataTableExportDialogRows'

  const radioOptions = [
    {
      label: t('all'),
      value: 'all',
    },
    {
      label: t('filteredRows'),
      value: 'filtered',
    },
    {
      label: t('unfilteredRows'),
      value: 'unfiltered',
    },
    {
      label: t('currentPage'),
      value: 'currentPage',
    },
  ] satisfies DataTableExportDialogRows

  return (
    <DataTableExportDialogSection title={t('rows')} titleId={id}>
      <div role="group" aria-labelledby={id} className="flex flex-col gap-2">
        {radioOptions.map((option) => {
          return (
            <RadioButtonControlled
              key={option.value}
              name="rows"
              value={option.value}
              label={option.label}
            />
          )
        })}
      </div>
    </DataTableExportDialogSection>
  )
}
