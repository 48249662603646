import { useField } from 'formik'
import { Select } from '../Select'
import type { SelectControlledProps } from './types'

export const SelectControlled = (props: SelectControlledProps) => {
  const { onChange: handleOnChange, ...rest } = props
  const [field, { error, touched }, { setValue }] = useField(props.name)

  return (
    <Select
      error={!!error && touched}
      onChange={(_, value) => {
        if (handleOnChange) {
          handleOnChange(null, value)
        }
        setValue(value)
      }}
      value={field.value || null} // mui select value must be null if no value
      {...rest}
    />
  )
}
