import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { initBugsnag } from './lib/bugsnag'

// eslint-disable-next-line react-refresh/only-export-components
const ErrorBoundary = initBugsnag()

async function enableMocking() {
  if (import.meta.env.VITE_ENABLE_MOCK_API !== 'true') {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  })
}

await enableMocking()

const rootElement = document.getElementById('app')!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  const app = (
    <StrictMode>
      <App />
    </StrictMode>
  )

  root.render(ErrorBoundary ? <ErrorBoundary>{app}</ErrorBoundary> : app)
}
