import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { MonthCalendar, PickersRangeCalendarHeader } from '@mui/x-date-pickers-pro'
import type { DateRange, RangePosition } from '@mui/x-date-pickers-pro'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEvent } from 'react-use'
import { monthPickerStyles } from './helpers'
import type { DateRangePickerFilterHeaderProps } from './types'

export const DateRangePickerFilterHeader = ({
  onMonthSelect,
  value,
  ...props
}: DateRangePickerFilterHeaderProps) => {
  const [startDate, endDate] = value

  const isStartCalendar = props.monthIndex === 0
  const [calendarAnchor, setCalendarAnchor] = useState<null | HTMLElement>(null)

  const { t } = useTranslation()

  const handleOpenCalendar = (event: React.MouseEvent<HTMLElement>) => {
    setCalendarAnchor(calendarAnchor ? null : event.currentTarget)
  }

  const isOpenCalendar = !!calendarAnchor
  const calendarId = isOpenCalendar ? 'month-popup' : undefined

  const popupRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClickOutside = (event: Event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target as Node) &&
      buttonRef.current !== event.target
    ) {
      setCalendarAnchor(null)
    }
  }

  useEvent('mousedown', handleClickOutside)

  const handleMonthChange = (date: Date) => {
    const finalDate: DateRange<Date> = isStartCalendar ? [date, endDate] : [startDate, date]

    const rangePosition: RangePosition = isStartCalendar ? 'start' : 'end'

    onMonthSelect(finalDate, rangePosition)
    setCalendarAnchor(null)
  }

  return (
    <div className="relative">
      <p className="w-full text-center text-xs font-semibold uppercase">
        {t(isStartCalendar ? 'dates.initialRange' : 'dates.finalRange')}
      </p>
      <button
        ref={buttonRef}
        className="absolute left-1/3 top-10 h-4 w-32"
        onClick={handleOpenCalendar}
      />
      <PickersRangeCalendarHeader {...props} />

      <BasePopup
        ref={popupRef}
        id={calendarId}
        open={isOpenCalendar}
        anchor={calendarAnchor}
        disablePortal
        placement="bottom-end"
        className="z-50 mt-2 rounded-lg border border-solid border-neutral-200 bg-white p-3 font-sans text-sm font-medium shadow-md"
      >
        <MonthCalendar
          onChange={handleMonthChange}
          sx={monthPickerStyles}
          value={isStartCalendar ? startDate : endDate}
          minDate={isStartCalendar ? undefined : (startDate as Date)}
        />
      </BasePopup>
    </div>
  )
}
