import { isValid, parseISO } from 'date-fns'
import { keyBy, omit } from 'lodash-es'
import { checkIfStringIsDate, formatStandardDate } from '~/utils/date'
import { ActionTypes } from './types'
import type {
  Action,
  Filter,
  FiltersGroupedByModule,
  PreProcessedDateFilters,
  ProcessedDateFilters,
} from './types'

const LOCAL_STORAGE_KEY = 'FILTERS_STATE'

const initialState: FiltersGroupedByModule = {}

export function initializeState(): FiltersGroupedByModule {
  const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (localStorageValue !== null) {
    return JSON.parse(localStorageValue, parseLocalStorageValue)
  }

  saveFiltersStateToLocalStorage(initialState)

  return initialState
}

export function filtersReducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ADD_FILTER: {
      return {
        ...state,
        [action.moduleId]: {
          ...state[action.moduleId],
          [action.filter.id]: action.filter,
        },
      }
    }

    case ActionTypes.ADD_FILTERS: {
      return {
        ...state,
        [action.moduleId]: {
          ...state[action.moduleId],
          ...keyBy(action.filters, 'id'),
        },
      }
    }

    case ActionTypes.UPSERT_FILTERS: {
      return {
        ...state,
        [action.moduleId]: {
          ...keyBy(action.filters, 'id'),
        },
      }
    }

    case ActionTypes.UPDATE_FILTER: {
      return {
        ...state,
        [action.moduleId]: {
          ...state[action.moduleId],
          [action.filter.id]: action.filter,
        },
      }
    }

    case ActionTypes.REMOVE_FILTER: {
      const updatedModuleFilters = { ...state[action.moduleId] }
      delete updatedModuleFilters[action.filterId]

      return {
        ...state,
        [action.moduleId]: updatedModuleFilters,
      }
    }

    case ActionTypes.REMOVE_FILTERS: {
      const updatedModuleFilters = { ...state[action.moduleId] }
      const filteredFilters = omit(updatedModuleFilters, action.filtersIds)

      return {
        ...state,
        [action.moduleId]: filteredFilters,
      }
    }

    case ActionTypes.CLEAR_FILTERS: {
      const updatedState = { ...state }
      delete updatedState[action.moduleId]

      return updatedState
    }

    default:
      return state
  }
}

export function filtersReducerLocalStorage(state: typeof initialState, action: Action) {
  const newState = filtersReducer(state, action)

  saveFiltersStateToLocalStorage(newState)

  return newState
}

function saveFiltersStateToLocalStorage(state: FiltersGroupedByModule) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
}

export function parseLocalStorageValue(key: string, value: unknown) {
  if (key === 'value') {
    // if value is a string and it is a valid date string parse it to a date
    if (typeof value === 'string' && checkIfStringIsDate(value)) {
      const date = parseISO(value)

      if (isValid(date)) {
        return date
      }
    }

    // if value is an array, check if the first item is a date string, if so, parse all array items to date
    if (Array.isArray(value) && value.length > 0 && checkIfStringIsDate(value[0])) {
      return value.map((item) => {
        const date = parseISO(item)

        if (isValid(date)) {
          return date
        }

        return item
      })
    }
  }

  return value
}

export function processFilter({ field, operator, value }: Filter) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parsedValue: any = value

  if (
    Array.isArray(parsedValue) &&
    parsedValue[0] &&
    typeof parsedValue[0] === 'object' &&
    'value' in parsedValue[0]
  ) {
    parsedValue = parsedValue.map(({ value }) => value)
  }

  return {
    field,
    operator,
    value: parsedValue,
  }
}

export const proccessDateFilter = (
  dateFilters: PreProcessedDateFilters,
): ProcessedDateFilters | undefined => {
  if (dateFilters.length === 0) {
    return undefined
  }

  const processedDateFilters = {} as ProcessedDateFilters

  const [mainStartDate, mainEndDate] = dateFilters

  const hasComparisonDates = dateFilters.length > 2

  //if has two dates, only has main date filters
  processedDateFilters.main = {
    start: formatStandardDate(mainStartDate.value as Date) || '',
    end: formatStandardDate(mainEndDate.value as Date) || '',
  }

  if (hasComparisonDates) {
    const [comparisonStartDate, comparisonEndDate] = dateFilters.slice(2)

    processedDateFilters.comparison = {
      start: formatStandardDate(comparisonStartDate.value as Date) || '',
      end: formatStandardDate(comparisonEndDate.value as Date) || '',
    }
  }

  return processedDateFilters
}

export const stringFilterOperatorsMap = {
  is: 'is',
  'is not': 'is not',
  'not in': 'not in',
  in: 'in',
  'is null': 'is null',
  'is not null': 'is not null',
  // Dont delete this operators. This will be available soon
  lessOrEqual: 'lessOrEqual',
  between: 'between',
  greaterOrEqual: 'greaterOrEqual',
} as const

export const numericFilterOperatorsMap = {
  'less than': 'less than',
  'greater than': 'greater than',
} as const

export const mergedOperatorsMap = { ...stringFilterOperatorsMap, ...numericFilterOperatorsMap }

export const activeStringOperators: Array<Partial<keyof typeof mergedOperatorsMap>> = [
  'in',
  'not in',
  'is null',
  'is not null',
]

export const numericFiltersOperators = Object.values(numericFilterOperatorsMap)

export const stringFiltersOperators = Object.values(stringFilterOperatorsMap)

export const mergedFilterOperators = Object.values(mergedOperatorsMap)
