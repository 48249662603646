import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

const scenarioCreationSearchParams = z.object({
  name: z.string().min(3),
  description: z.string().optional(),
})

export const Route = createFileRoute('/_layout/planning/scenarios/create/')({
  validateSearch: zodSearchValidator(scenarioCreationSearchParams),
})
