import { IconExternalLink } from '@tabler/icons-react'
import clsx from 'clsx'
import type { ComponentProps } from 'react'

interface ExternalLinkProps extends ComponentProps<'a'> {}

export const ExternalLink = ({ children, className, ...rest }: ExternalLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={clsx('inline-flex items-center gap-1', className)}
      {...rest}
    >
      {children}
      <IconExternalLink size={12} />
    </a>
  )
}
