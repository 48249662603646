import clsx from 'clsx/lite'
import type { RadioButtonProps } from './types'

export const RadioButton = ({ slotProps, name, ...props }: RadioButtonProps) => {
  return (
    <label
      className={clsx(
        'inline-flex items-center',
        !props.disabled && 'cursor-pointer',
        slotProps?.root?.className,
      )}
    >
      <input
        name={name}
        type="radio"
        className={clsx(
          "before:content[''] peer relative mr-2 size-5 appearance-none rounded-full border-[3px] border-night-200 text-azure transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:size-[11px] before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-azure checked:before:bg-azure checked:before:opacity-100 disabled:border-night-200 disabled:bg-night-100 disabled:before:bg-night-300 checked:disabled:border-night-300",
          slotProps?.input?.className,
        )}
        {...props}
      />
      <span
        className={clsx(
          'mt-px font-light text-night-600 peer-disabled:text-night-500',
          slotProps?.label?.className,
        )}
      >
        {props.label}
      </span>
    </label>
  )
}
