import { IconCheck } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { forwardRef } from 'react'
import type { CheckboxProps } from './types'

export const Checkbox = forwardRef(function Checkbox(
  props: CheckboxProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { className, inputClassName, labelClassName, checkboxClassName, label, ...inputProps } =
    props

  return (
    <label className={clsx('inline-flex cursor-pointer items-center gap-2', className)}>
      <span className={clsx('relative size-5', checkboxClassName)}>
        <input
          type="checkbox"
          className={clsx(
            "before:content[''] peer size-5 appearance-none rounded border-[3px] border-night-200 transition-all checked:border-azure checked:bg-azure",
            'disabled: disabled:checked:border-night-300 disabled:checked:bg-night-300',
            inputClassName,
          )}
          {...inputProps}
          ref={ref}
        />
        <IconCheck
          className="absolute left-2/4 top-2/4 block -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100"
          size={18}
          stroke={3}
        />
      </span>
      <span className={clsx('text-body-2', labelClassName)}>{label}</span>
    </label>
  )
})
