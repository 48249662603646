import type { OptionalClassName } from '~/types'

export const IconSpain = ({ className }: OptionalClassName) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_89_843)">
        <path
          d="M0 7.00001C0 7.85625 0.154027 8.67646 0.435395 9.43478L7 10.0435L13.5646 9.43478C13.846 8.67646 14 7.85625 14 7.00001C14 6.14377 13.846 5.32357 13.5646 4.56524L7 3.95654L0.435395 4.56524C0.154027 5.32357 0 6.14377 0 7.00001H0Z"
          fill="#FFDA44"
        />
        <path
          d="M13.5648 4.56523C12.5757 1.89965 10.0099 0 7.00015 0C3.9904 0 1.4246 1.89965 0.435547 4.56523H13.5648Z"
          fill="#D80027"
        />
        <path
          d="M0.435547 9.43475C1.4246 12.1003 3.9904 14 7.00015 14C10.0099 14 12.5757 12.1003 13.5648 9.43475H0.435547Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_89_843">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
