import { colors } from '~/theme/colors'

export const NotFoundSvg = () => {
  return (
    <svg
      width="531"
      height="332"
      viewBox="0 0 531 332"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="265" cy="147" r="147" fill={colors.lightblue} />
      <circle cx="265" cy="147" r="104" fill={colors.ghostwhite} />
      <circle cx="265" cy="147" r="59" fill="white" />
      <path
        d="M300 133C300 128.582 303.582 125 308 125H333C346.255 125 357 135.745 357 149C357 162.255 346.255 173 333 173H308C303.582 173 300 169.418 300 165V133Z"
        fill="white"
        stroke={colors.night[800]}
        strokeWidth="8"
      />
      <path
        d="M230.5 134C230.5 129.582 226.918 126 222.5 126H197.5C184.245 126 173.5 136.745 173.5 150C173.5 163.255 184.245 174 197.5 174H222.5C226.918 174 230.5 170.418 230.5 166V134Z"
        fill="white"
        stroke={colors.night[800]}
        strokeWidth="8"
      />
      <path d="M278 138.5H300" stroke={colors.night[800]} strokeWidth="8" strokeLinecap="round" />
      <path d="M219 139H228" stroke={colors.night[800]} strokeWidth="8" strokeLinecap="round" />
      <path d="M278 161H300" stroke={colors.night[800]} strokeWidth="8" strokeLinecap="round" />
      <path d="M219 161H228" stroke={colors.night[800]} strokeWidth="8" strokeLinecap="round" />
      <path
        d="M358 151.5C363.667 150.5 378.1 150.7 390.5 159.5C406 170.5 418 177.5 441 170C464 162.5 465 147 489 159.5C508.2 169.5 522 169 526.5 167.5"
        stroke={colors.night[800]}
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M172.5 152.5C166.833 151.5 152.4 151.7 140 160.5C124.5 171.5 112.5 178.5 89.5 171C66.5 163.5 65.5 148 41.5 160.5C22.3 170.5 8.5 170 4 168.5"
        stroke={colors.night[800]}
        strokeWidth="8"
        strokeLinecap="round"
      />
      <circle cx="454" cy="307" r="19.5" stroke={colors.lightblue} strokeWidth="11" />
      <path
        d="M123.5 77.75L101 98.75"
        stroke={colors.columbiablue}
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M122.75 99.5L101.75 77"
        stroke={colors.columbiablue}
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  )
}
