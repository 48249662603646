import { format, isValid, parseISO } from 'date-fns'
import { getLanguageFromLocalStorage } from '~/lib/i18n'

export const STANDARD_DATE_FORMAT = 'yyyy-MM-dd'

export function getDateRangeStr(start: Date, end: Date, lang: string) {
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }

  return `${start.toLocaleDateString(lang, options)} - ${end.toLocaleDateString(lang, options)}`
}

/**
 * Checks if a string is a valid date in the format "YYYY-MM-DDTHH:mm:ss.SSSZ" (ISO 8601).
 * @param value - The string to be checked.
 * @returns A boolean indicating whether the string is a valid date.
 */
export function checkIfStringIsDate(value: string) {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
}

/** Basic parse API date string from the api which is YYYY-MM-DD */
export function parseDate(date: string) {
  const parsedDate = parseISO(date)

  return isValid(parsedDate) ? parsedDate : null
}

/**
 * Formats a date to a string.
 * It uses the `Intl.DateTimeFormat` API.
 *
 * @param date - The date to be formatted.
 * @param monthVariant - The format month variant to be used. It can be either "short" or "long". Defaults to "short".
 * If "short", the format will be "D MMM YYYY" (e.g. "Jan 1, 2022"). If "long", the format will be "d MMMM YYYY" (e.g. "January 1, 2022").
 */
export function formatDate(date: Date, monthVariant: 'short' | 'long' = 'short'): string {
  if (!isValid(date)) {
    return ''
  }

  const locale = getLanguageFromLocalStorage()
  const dayMonthFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric', month: monthVariant })
  const year = date.getFullYear()

  return `${dayMonthFormatter.format(date)}, ${year}`
}

export function formatShortDate(date: Date | string) {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date
  return formatDate(parsedDate, 'short')
}

export function formatMonth(date: Date, locale: string = 'en-US') {
  return new Intl.DateTimeFormat(locale, { month: 'long' }).format(date)
}

/**
 * Formats a date to a string in the format "MMMM yyyy" (e.g. "January 2022").
 *
 * It uses the `Intl.DateTimeFormat` API.
 */
export function formatMonthYear(date: Date, locale: string = 'en-US'): string {
  const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' })
  const yearFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric' })

  const month = monthFormatter.format(date)
  const year = yearFormatter.format(date)

  return `${month} ${year}`
}

/**
 * Formats a date to a string that represents the day of the week (e.g. "Monday").
 *
 * It uses the `Intl.DateTimeFormat` API.
 */
export function getDayName(date: Date, locale: string = 'en-US'): string {
  return new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(date)
}

/** Format a date to the standard format "YYYY-MM-DD" */
export function formatStandardDate(date: Date) {
  if (!isValid(date)) {
    return ''
  }

  return format(date, STANDARD_DATE_FORMAT)
}

/**
 * Formats a given Date object to a locale-specific string.
 *
 * For example:
 * In 'es' locale it will return the date in the format 'dd/MM/yyyy'.
 * In 'en' locale it will return the date in the format 'MM/dd/yyyy'.
 */
export function formatDateToLocaleString(value: Date | null) {
  if (!value || !isValid(value)) {
    return '-'
  }

  const locale = getLanguageFromLocalStorage()
  return new Intl.DateTimeFormat(locale).format(value)
}

export const getWeekDays = (): string[] => {
  const weekDays: string[] = []
  const baseDate = new Date()

  for (let i = 0; i < 7; i++) {
    const day = new Date(baseDate.setDate(baseDate.getDate() - baseDate.getDay() + i))
    weekDays.push(format(day, 'EEE'))
  }

  return weekDays
}
