import type { GridValidRowModel } from '@mui/x-data-grid-premium'
import type { GetColumnsBaseParams } from '~/types/shared'
import { processApiColumns, wrapActionsColDef } from '~/utils/dataGrid'

export const getColumns = <R extends GridValidRowModel = GridValidRowModel>({
  apiColumns = [],
  getActions,
}: GetColumnsBaseParams<R>) => {
  const columns = processApiColumns(apiColumns, {
    defaultColDefProps: { flex: 1 },
  })

  columns.push(wrapActionsColDef({ getActions, width: 100 }))

  return columns
}
