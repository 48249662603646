import type { GridColDef } from '@mui/x-data-grid-premium'
import { z } from 'zod'

export const validationSchema = z.object({
  dateRange: z.enum([
    'today',
    'yesterday',
    'currentMonth',
    'last7Days',
    'lastMonth',
    'all',
    'custom',
  ]),
  columns: z.array(z.string()),
  rows: z.enum(['currentPage', 'all', 'filtered', 'unfiltered']),
})

export function checkIfColumnIsExportable(column: GridColDef) {
  return !column.disableExport && column.field
}
