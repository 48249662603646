import type { TFunction } from 'i18next'

export const DataTableTranslation = (t: TFunction<'common', undefined>) => {
  return {
    noRowsLabel: t('statusMessage.noData'),
    columnsManagementShowHideAllText: t('action.showHideAll'),
    columnsManagementSearchTitle: t('action.search'),
    columnsManagementReset: t('action.reset'),
    footerRowSelected: (count: number) => t('selectedRow', { count }),
    footerTotalRows: `${t('totalRows')}:`,
    columnMenuSortAsc: t('menuDataTable.columnMenuSortAsc'),
    columnMenuSortDesc: t('menuDataTable.columnMenuSortDesc'),
    columnMenuFilter: t('menuDataTable.columnMenuFilter'),
    pinToLeft: t('menuDataTable.pinToLeft'),
    pinToRight: t('menuDataTable.pinToRight'),
    unpin: t('menuDataTable.unpin'),
    groupColumn: (name: string) => `${t('menuDataTable.groupColumn')} ${name}`,
    columnMenuHideColumn: t('menuDataTable.columnMenuHideColumn'),
    columnMenuManageColumns: t('menuDataTable.columnMenuManageColumns'),
    columnMenuUnsort: t('menuDataTable.columnMenuUnsort'),
    unGroupColumn: (name: string) => `${t('menuDataTable.unGroupColumn')} ${name}`,
    aggregationMenuItemHeader: t('menuDataTable.aggregationMenuItemHeader'),
    aggregationFunctionLabelSum: t('menuDataTable.aggregationFunctionLabelSum'),
    aggregationFunctionLabelAvg: t('menuDataTable.aggregationFunctionLabelAvg'),
    aggregationFunctionLabelMin: t('menuDataTable.aggregationFunctionLabelMin'),
    aggregationFunctionLabelMax: t('menuDataTable.aggregationFunctionLabelMax'),
    aggregationFunctionLabelSize: t('menuDataTable.aggregationFunctionLabelSize'),
  }
}
