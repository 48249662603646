import { InfoContent } from './InfoContent'
import { LeftContent } from './LeftContent'
import { PageTopbarRoot } from './PageTopbarRoot'
import { RightContent } from './RightContent'
import { TabsContent } from './TabsContent'

export const PageTopbar = {
  Root: PageTopbarRoot,
  Info: InfoContent,
  Tabs: TabsContent,
  RightContent: RightContent,
  LeftContent: LeftContent,
}
