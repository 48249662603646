import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

export const useMixpanelInit = () => {
  useEffect(() => {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      cross_subdomain_cookie: false,
      loaded: (mixpanel) => {
        if (import.meta.env.DEV) {
          mixpanel.disable()

          return
        }

        // global event properties (they are named 'super properties' in mixpanel)
        mixpanel.register({
          'App Version': __APP_VERSION__,
          'App Build': __COMMIT_HASH__,
          'Celes Version': 'v3',
        })
      },
    })
  }, [])
}
