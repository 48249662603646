import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { KNOWLEDGE_BASE_PAGE } from '~/lib/queryKeys'
import type {
  ApiResponse,
  KnowledgeBasePageParams,
  KnowledgeBasePageResponse,
} from '~/types/apiContracts'

async function fetchKnowledgeBasePage(params: KnowledgeBasePageParams) {
  const res = (await api
    .get('knowledge-base/summary', {
      searchParams: {
        content_id: params.content_id,
      },
    })
    .json()) as ApiResponse<KnowledgeBasePageResponse>

  return res.data
}

export function useKnowledgeBasePageQuery(params: KnowledgeBasePageParams) {
  return useQuery({
    queryKey: [KNOWLEDGE_BASE_PAGE, params],
    queryFn: () => fetchKnowledgeBasePage(params),
    enabled: !!params.content_id,
  })
}
