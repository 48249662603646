/**
 * Contains utilities to get translations using built-in Intl API.
 */
import type { WeekDayCode } from './core/weekDays'
import { getLanguageFromLocalStorage } from './i18n'

const weekDayNamesByLocale: { [locale: string]: string[] } = {}

/**
 * Retrieves an array of week day names based on the specified locale.
 * If the locale is not found in the cache, it generates the week day names and caches them for future use.
 *
 * @param locale - The locale for which to retrieve the week day names. Defaults to 'en-US'.
 * @returns An 7-length array of week day names. The first element is Sunday, the second is Monday, and so on.
 */
export function getWeekDayNames(locale = getLanguageFromLocalStorage()): string[] {
  if (!(locale in weekDayNamesByLocale)) {
    const weekDayNames = Array.from({ length: 7 }, (_, day) => {
      const date = new Date(1970, 0, day + 4) // 1970-01-04 is a Sunday
      return date.toLocaleDateString(locale, { weekday: 'long' })
    })

    weekDayNamesByLocale[locale] = weekDayNames
  }

  return weekDayNamesByLocale[locale]
}

type WeedDayCodeNameMap = {
  [code in WeekDayCode]: string
}

const weekDayNamesByCodeByLocale: {
  [locale: string]: WeedDayCodeNameMap
} = {}

export function getTranslatedWeekDayCodesMap(
  locale = getLanguageFromLocalStorage(),
): WeedDayCodeNameMap {
  if (!(locale in weekDayNamesByCodeByLocale)) {
    const weekDaysNames = getWeekDayNames(locale)

    weekDayNamesByCodeByLocale[locale] = {
      m: weekDaysNames[1],
      t: weekDaysNames[2],
      w: weekDaysNames[3],
      th: weekDaysNames[4],
      f: weekDaysNames[5],
      s: weekDaysNames[6],
      su: weekDaysNames[0],
    }
  }

  return weekDayNamesByCodeByLocale[locale]
}
