import { Button as BaseButton } from '@mui/base/Button'
import type { Icon } from '@tabler/icons-react'
import { IconLoader2 } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { pick } from 'lodash-es'
import React from 'react'
import { buttonClasses } from './buttonClasses'
import type { ButtonProps } from './types'

/**
 * Primary UI component for user interaction.
 */
export const Button = React.forwardRef(function Button(
  { variant, size, icon, startIcon, endIcon, isLoading, onClick, ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isLoading) {
      e.preventDefault()
      return
    }

    if (onClick) {
      onClick(e)
    }
  }

  const cvaProps = pick(
    {
      variant,
      size,
    },
    ['variant', 'size'],
  )

  let content: React.ReactNode

  const isIconButton = !!icon
  const iconSize = size === 'small' ? 20 : size === 'large' ? 28 : 24

  if (isIconButton) {
    const IconComponent = icon as Icon

    content = <IconComponent size={iconSize} />
  } else {
    const hasStartIcon = !!startIcon
    const hasEndIcon = !!endIcon
    const StartIconComponent = startIcon as Icon
    const EndIconComponent = endIcon as Icon
    const endIconClasses = 'ms-3'

    content = (
      <>
        {hasStartIcon && <StartIconComponent size={iconSize} className="me-3" />}
        {props.children}
        {isLoading && !hasEndIcon && (
          <IconLoader2 size={iconSize} className={clsx('out animate-spin', endIconClasses)} />
        )}
        {!isLoading && hasEndIcon && (
          <EndIconComponent size={iconSize} className={endIconClasses} />
        )}
      </>
    )
  }

  return (
    <BaseButton
      {...props}
      slotProps={{
        root: {
          className: clsx(buttonClasses({ ...cvaProps, icon: isIconButton }), props.className),
        },
      }}
      title={typeof props.children === 'string' ? props.children : undefined}
      ref={ref}
      onClick={handleClick}
    >
      {content}
    </BaseButton>
  )
})
