import type { GridValidRowModel } from '@mui/x-data-grid-premium'
import type { ApiColDef, BaseList, WithInputQuantity } from '~/types/apiContracts'
import { findColumnWithSuggestedInputValue } from '~/utils/dataGrid'
import type { CalculatedColumnParsedSerializedFunction } from './calculatedColumns'
import {
  checkIfColumnIsCalculated,
  evaluateParsedFunction,
  getCalculatedColumnValue,
  parseSerializedFunction,
} from './calculatedColumns'

type GetInitialInputQuantityParams<T extends GridValidRowModel> = {
  item: T
  columnWithSuggestedInputValue: ApiColDef
  parsedSerializedFunction?: CalculatedColumnParsedSerializedFunction // to be used when serialized function is already defined
}

export function getSuggestedInputQuantity<T extends GridValidRowModel>({
  item,
  columnWithSuggestedInputValue,
  parsedSerializedFunction,
}: GetInitialInputQuantityParams<T>): number {
  if (parsedSerializedFunction) {
    try {
      return evaluateParsedFunction(parsedSerializedFunction, item)
    } catch (error) {
      return 0
    }
  }

  if (checkIfColumnIsCalculated(columnWithSuggestedInputValue)) {
    try {
      return getCalculatedColumnValue(columnWithSuggestedInputValue, item)
    } catch (error) {
      return 0
    }
  }

  return (item[columnWithSuggestedInputValue.name as keyof T] ?? 0) as number
}

export function transformProductListWithInputQuantity<T extends WithInputQuantity>(
  data: BaseList<T>,
): BaseList<T> {
  const columnWithSuggestedInputValue = findColumnWithSuggestedInputValue(data.columns)

  if (!columnWithSuggestedInputValue) {
    return data
  }

  let parsedSerializedFunction: CalculatedColumnParsedSerializedFunction | undefined

  if (checkIfColumnIsCalculated(columnWithSuggestedInputValue)) {
    // we get the parsed function here to avoid parsing it multiple times for each item
    try {
      parsedSerializedFunction = parseSerializedFunction(columnWithSuggestedInputValue.formula)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    ...data,
    items: data.items.map((item) => {
      const hasValidInputQuantity = item.input_quantity !== null && item.input_quantity !== -1

      if (hasValidInputQuantity) {
        return item
      }

      const initialInputQuantity = getSuggestedInputQuantity({
        item,
        columnWithSuggestedInputValue,
        parsedSerializedFunction,
      })

      return {
        ...item,
        input_quantity: initialInputQuantity,
      }
    }),
  }
}

export function formatColumnsGroupLabel(label: string) {
  const columnLabel = label.split(/y|,/)
  return columnLabel.join(' | ')
}
