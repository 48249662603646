import { useLocationPathname } from '~/hooks/useLocationPathname'
import type { RoutePath } from '~/types/shared'
import { removeTrailingSlash } from '~/utils/string'
import type { TabItemLink } from '../UI/Tabs'
import { Tabs } from '../UI/Tabs'

interface TabsContentProps {
  tabsItems: TabItemLink[]
}

export const TabsContent = ({ tabsItems }: TabsContentProps) => {
  const locationPathname = useLocationPathname()
  const defaultCategory = removeTrailingSlash(locationPathname) as RoutePath

  if (!tabsItems.length) {
    return
  }

  return (
    <div className="self-end">
      <Tabs defaultTabKey={defaultCategory} items={tabsItems!} showContent={false} asNav />
    </div>
  )
}
