import clsx from 'clsx'
import type { PropsWithChildren } from 'react'
import { useModuleId } from '~/hooks/useModuleId'
import type { ModuleId } from '~/lib/constants'
import type { ContainerWithClassName } from '~/types/shared'
import { DateRangePickerFilter } from '../DateRangePickerFilter'
import { getDefaultDateRange } from '../DateRangePickerFilter/helpers'
import type { DateRangePickerFilterProps } from '../DateRangePickerFilter/types'
import { MainFilters } from '../MainFilters'
import type { MainFiltersModalProps } from '../MainFilters/types'

interface RightContentProps
  extends PropsWithChildren,
    ContainerWithClassName,
    Pick<MainFiltersModalProps, 'confirmFiltersModalSubmit'> {
  showFilters?: boolean
  showDateFilters?: boolean
  moduleId?: ModuleId
  dateRangePickerFilterProps?: Omit<DateRangePickerFilterProps, 'moduleId'>
}

export const RightContent = ({
  className,
  children,
  moduleId: _moduleId,
  dateRangePickerFilterProps,
  confirmFiltersModalSubmit,
  showFilters,
  showDateFilters,
}: RightContentProps) => {
  const pathnameModuleId = useModuleId()
  const moduleId = _moduleId || pathnameModuleId
  const defaultMainDateRange = getDefaultDateRange()

  return (
    <div
      className={clsx(
        'ms-auto flex flex-shrink-0 flex-wrap items-center gap-4 pb-3 pt-2.5 md:pb-2 lg:pb-2',
        className,
      )}
    >
      {showFilters && (
        <MainFilters moduleId={moduleId} confirmFiltersModalSubmit={confirmFiltersModalSubmit} />
      )}

      {showDateFilters && (
        <DateRangePickerFilter
          moduleId={moduleId}
          {...dateRangePickerFilterProps}
          defaultMainDateRange={
            dateRangePickerFilterProps?.defaultMainDateRange || defaultMainDateRange.value
          }
        />
      )}

      {children}
    </div>
  )
}
