import { Badge as BaseBadge } from '@mui/base/Badge'
import clsx from 'clsx/lite'
import type { BadgeProps } from './types'

export const Badge = (props: BadgeProps) => {
  return (
    <BaseBadge
      {...props}
      slotProps={{
        badge: {
          className: clsx(
            'text-caption-4 absolute end-0 top-0 flex h-4 min-w-4 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-xl px-1.5 text-white',
            (!props.color || props.color === 'default') && 'bg-night-600',
            props.color === 'primary' && 'bg-azure',
            props.invisible && 'invisible',
          ),
        },
      }}
      className={clsx('relative inline-block', props.className)}
    />
  )
}
