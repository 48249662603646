import { z } from 'zod'
import type { FiltersSetFormValues } from './types'

export const initialValues: FiltersSetFormValues = {
  name: '',
  saveFor: 'justMe',
}

export const validationSchema = z.object({
  name: z.string().trim().min(1),
  saveFor: z.enum(['justMe', 'all']),
})
