import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/UI/Button'
import { Loader } from '~/components/UI/Loader'
import { Modal } from '~/components/UI/Modal'
import { useFiltersSetDeleteMutation } from '~/hooks/mutations/useFiltersSetDeleteMutation'
import { useSavedFiltersSetsQuery } from '~/hooks/queries'
import { FiltersSetChip } from './FiltersSetChip'
import type { FiltersSetsProps } from './types'

export const FiltersSets = (props: FiltersSetsProps) => {
  const { t } = useTranslation(['common', 'filter'])
  const savedFiltersSetQuery = useSavedFiltersSetsQuery(props.moduleId)
  const deleteFiltersSetMutation = useFiltersSetDeleteMutation()

  const [filterToDelete, setFilterToDelete] = useState('')

  const handleDelete = () => {
    setFilterToDelete('')
    deleteFiltersSetMutation.mutate({
      moduleId: props.moduleId,
      filtersSetName: filterToDelete,
    })
  }

  return (
    <div className="mt-10 flex flex-wrap gap-1.5">
      {savedFiltersSetQuery.isLoading && (
        <div className="flex items-center gap-1">
          <Loader iconSize={25} />

          <span> {t('status.loading')}</span>
        </div>
      )}
      {savedFiltersSetQuery.isError && <div>{t('status.error')}</div>}
      {savedFiltersSetQuery.isSuccess && (
        <>
          {savedFiltersSetQuery.data?.filters.length === 0 && <div>{t('noSavedFilters')}</div>}
          {savedFiltersSetQuery.data?.filters?.map((filtersSet) => {
            const isDeletingFiltersSet =
              deleteFiltersSetMutation.isPending &&
              deleteFiltersSetMutation.variables?.filtersSetName === filtersSet.name

            return (
              <FiltersSetChip
                key={filtersSet.name}
                label={filtersSet.name}
                onClick={() => props.onClickFilterSet(JSON.parse(filtersSet.filter_string))}
                onDelete={() => setFilterToDelete(filtersSet.name)}
                disabled={isDeletingFiltersSet}
                loading={isDeletingFiltersSet}
              />
            )
          })}

          <Modal.Root open={!!filterToDelete} className="px-20 py-10">
            <Modal.Content>
              <div className="flex flex-col items-center gap-5">
                <h2 className="text-subtitle-2-strong">
                  {t('action.delete')} {t('filter')}
                </h2>
                <span>
                  {t('filter:actions.confirmFilterDeletion', { filterName: filterToDelete.trim() })}
                </span>
                <div className="flex items-center gap-8">
                  <Button variant="secondary" onClick={() => setFilterToDelete('')}>
                    {t('action.cancel')}
                  </Button>
                  <Button onClick={handleDelete}>{t('action.delete')}</Button>
                </div>
              </div>
            </Modal.Content>
          </Modal.Root>
        </>
      )}
    </div>
  )
}
