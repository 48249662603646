// type safe 'omit' function. Taken from: https://stackoverflow.com/a/53968837/6545201

interface Omit {
  <T extends object, K extends [...(keyof T)[]]>(
    obj: T,
    ...keys: K
  ): {
    [K2 in Exclude<keyof T, K[number]>]: T[K2]
  }
}

/**
 * Creates a new object by omitting specified keys from the input object.
 * Based on Lodash's `omit` function but type-safe.
 *
 * @param obj - The input object.
 * @param keys - The keys to omit from the object.
 * @returns A new object with the specified keys omitted.
 */
export const omit: Omit = (obj, ...keys) => {
  const ret = {} as {
    [K in keyof typeof obj]: (typeof obj)[K]
  }
  let key: keyof typeof obj
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key]
    }
  }
  return ret
}

/**
 * Splits an object into two separate objects based on the specified keys.
 *
 * @param obj - The object to split.
 * @param keys - The keys to split the object by.
 * @returns An array containing two objects: the first object contains the specified keys
 * and their corresponding values from the input object, and the second object contains
 * the remaining keys and their corresponding values.
 */
export function splitObject<Obj extends object, Keys extends (keyof Obj)[]>(
  obj: Obj,
  keys: Keys,
): [
  {
    [K in Keys[number]]: Obj[K]
  },
  {
    [K in Exclude<keyof Obj, Keys[number]>]: Obj[K]
  },
] {
  const ret = {} as {
    [K in keyof Obj]: Obj[K]
  }
  const rest = {} as { [K in Exclude<keyof Obj, Keys[number]>]: Obj[K] }

  let key: keyof Obj

  for (key in obj) {
    if (keys.includes(key)) {
      ret[key] = obj[key]
    } else {
      ;(rest as typeof ret)[key] = obj[key]
    }
  }

  return [ret, rest]
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
  link.remove()
}
