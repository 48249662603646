import { IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import type { TagProps } from './types'

export const AutocompleteTag = (props: TagProps) => {
  const { label, onDelete, disableDelete, ...other } = props

  return (
    <div
      className={clsx(
        'm-1 box-content flex w-auto max-w-52 items-center overflow-hidden truncate rounded-lg border border-night-200',
        other.size === 'small' && 'h-3 px-1 py-0 text-xs',
        other.size === 'medium' && 'h-4 py-1 pl-3 pr-2 text-sm',
        other.size === 'large' && 'h-6 pl-2',
      )}
      {...other}
    >
      <span className="text-body-2-strong overflow-hidden overflow-ellipsis whitespace-nowrap">
        {label}
      </span>
      <button className="p-1" onClick={onDelete} disabled={disableDelete}>
        <IconX className="size-4 text-night-300" />
      </button>
    </div>
  )
}
