import { useCallback, useMemo, useState } from 'react'
import { Modal } from '~/components/UI/Modal'
import { FullScreenContext } from './FullScreenContext'
import type { FullScreenContextValue, FullScreenProviderProps } from './types'

export const FullScreenProvider = ({ children }: FullScreenProviderProps) => {
  const [isFullScreenModalOpen, setIsFullScreenModalOpen] = useState(false)
  const [modalContentEl, setModalContentEl] = useState<HTMLDivElement | null>(null)

  const toggleFullScreenModal = useCallback(() => {
    setIsFullScreenModalOpen(!isFullScreenModalOpen)
  }, [isFullScreenModalOpen])

  const handleCloseModal = useCallback(() => {
    setIsFullScreenModalOpen(false)
  }, [])

  const contextValue: FullScreenContextValue = useMemo(() => {
    return {
      toggleFullScreenModal,
      handleCloseModal,
      isFullScreenModalOpen,
      modalContentEl: modalContentEl,
    }
  }, [toggleFullScreenModal, handleCloseModal, isFullScreenModalOpen, modalContentEl])

  return (
    <FullScreenContext.Provider value={contextValue}>
      {children}

      <Modal.Root
        open={isFullScreenModalOpen}
        onClose={handleCloseModal}
        className="h-screen w-screen"
        renderCloseButton
        onMountContentWrapperElement={(el) => {
          setModalContentEl(el)
        }}
      />
    </FullScreenContext.Provider>
  )
}
