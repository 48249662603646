import { IconDeviceFloppy, IconLockAccess, IconSend } from '@tabler/icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageTopbar } from '~/components/PageTopbar'
import { ToggleDrawerButton } from '~/components/ToggleDrawerButton'
import { Button } from '~/components/UI/Button'
import { PageContent } from '~/components/UI/PageContent'
import { RadioButton } from '~/components/UI/RadioButton'
import { InventoryBalancingDetail } from './components/InventoryBalancingDetail'
import { InventoryBalancingTable } from './components/InventoryBalancingTable'

export const InventoryBalancingPage = () => {
  const { t } = useTranslation(['common', 'work-area'])
  const [isSelectedItem, setIsSelectedItem] = useState(false)

  const id = 'restriction_options'

  const radioOptions = [
    {
      label: 'Retorno a CEDI',
      value: '1',
    },
    {
      label: 'Distribución entre tiendas',
      value: '2',
    },
  ]

  return (
    <>
      <PageTopbar.Root>
        <PageTopbar.LeftContent>
          <h2 className="text-body-2">{t('work-area:productsList')}</h2>
          <p>_</p>
        </PageTopbar.LeftContent>
        <PageTopbar.RightContent showFilters>
          <Button size="large" startIcon={IconSend}>
            {t('work-area:inventoryBalancing.actions.sendOrder')}
          </Button>
          <Button size="large" variant={'secondary'}>
            {t('common:saveChanges')}
          </Button>
        </PageTopbar.RightContent>
      </PageTopbar.Root>
      <PageContent.Root>
        <InventoryBalancingTable onView={() => setIsSelectedItem(true)} />
        <InventoryBalancingDetail
          isOpen={isSelectedItem}
          onClose={() => setIsSelectedItem(false)}
        />
      </PageContent.Root>
      <ToggleDrawerButton
        title={t('work-area:inventoryBalancing.actions.editRestictions')}
        icon={<IconLockAccess />}
        disableDefaultHeight
        className="max-h-60"
      >
        <div className="h-full w-full">
          <div className="flex justify-between p-4 pl-8">
            <div className="flex gap-3">
              <IconLockAccess size={24} className="text-azure" />
              <div>
                <h3 className="text-body-1-strong">
                  {t('work-area:inventoryBalancing.actions.editRestictions')}
                </h3>
                <p className="text-body-2">{t('work-area:inventoryBalancing.minimumValue')}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col px-8 py-4">
            <div className="flex flex-1 gap-2">
              <div role="group" aria-labelledby={id} className="flex flex-col gap-2">
                {radioOptions.map((option) => {
                  return (
                    <RadioButton
                      key={option.value}
                      name="rows"
                      value={option.value}
                      label={option.label}
                    />
                  )
                })}
              </div>
            </div>
            <div className="flex justify-end">
              <Button size="large" startIcon={IconDeviceFloppy}>
                {t('common:action.save')}
              </Button>
            </div>
          </div>
        </div>
      </ToggleDrawerButton>
    </>
  )
}
