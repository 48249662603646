import type { WithOptionalOwnerState } from '@mui/base'
import type { LinkProps } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'
import React from 'react'

/**
 * Tanstack Router adapter to be used with MUI components
 */
export const LinkAdapter = React.forwardRef(function RouterLink(
  props: LinkProps &
    React.HTMLAttributes<HTMLAnchorElement> &
    WithOptionalOwnerState<{ ownerState: unknown }>,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const { ownerState, ...other } = props
  return <Link {...other} ref={ref} />
})
