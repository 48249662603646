import clsx from 'clsx/lite'
import type { ContainerWithClassName } from '~/types/shared'

interface PageContentRootProps extends ContainerWithClassName {}

export const PageContentRoot = (props: PageContentRootProps) => {
  return (
    <div
      className={clsx(
        'flex flex-grow flex-col overflow-y-auto bg-seasalt px-4 pb-6 pt-4 md:px-6',
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
