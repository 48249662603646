import type { ModuleDataCommonParams, ModuleDataCommonParamsParsed } from '~/types/shared'

export function parseModuleDataCommonParams(
  params: ModuleDataCommonParams,
): ModuleDataCommonParamsParsed {
  const result: ModuleDataCommonParamsParsed = {}

  if (params.moduleId) {
    result.app_name = params.moduleId
  }

  if (params.paginationModel) {
    result.page = params.paginationModel.page
    result.page_size = params.paginationModel.pageSize
  }

  if (params.sortModel && Array.isArray(params.sortModel) && params.sortModel[0]) {
    if (params.sortModel[0].sort !== null) {
      result.sort_direction = params.sortModel[0].sort
    }

    result.sort_field = params.sortModel[0].field
  }

  if (params.dateFilters) {
    result.main_start_date = params.dateFilters.main.start
    result.main_end_date = params.dateFilters.main.end

    if (params.dateFilters?.comparison) {
      result.comparison_start_date = params.dateFilters.comparison.start
      result.comparison_end_date = params.dateFilters.comparison.end
    }
  }

  if (params.group_fields) {
    result.group_fields = params.group_fields
  }

  if (typeof params.only_suggestions === 'boolean') {
    result.only_suggestions = params.only_suggestions
  }

  if (params.key_date) {
    result.key_date = params.key_date
  }

  return result
}
