import {
  gridPageCountSelector,
  gridPaginationSelector,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { type MutableRefObject } from 'react'
import { Pagination } from '../Pagination'
import type { DataTableProps } from './types'

type DataTablePaginationProps = {
  apiRef: MutableRefObject<GridApiPremium>
  rootProps: DataTableProps
}

export const DataTablePagination = (props: DataTablePaginationProps) => {
  const { apiRef } = props

  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const {
    paginationModel: { page, pageSize },
  } = useGridSelector(apiRef, gridPaginationSelector)

  return (
    <Pagination
      page={page}
      pageSize={pageSize}
      pageCount={pageCount}
      pageSizeOptions={props.rootProps.pageSizeOptions}
      onChangePage={(page) => {
        apiRef.current.setPage(page)
      }}
      onChangePageSize={(pageSize) => {
        apiRef.current.setPageSize(pageSize)
      }}
    />
  )
}
