import { useCallback, useMemo, useReducer } from 'react'
import { FiltersContext } from './FiltersContext'
import { filtersReducerLocalStorage, initializeState } from './helpers'
import { ActionTypes } from './types'
import type { FiltersContextValue, FiltersProviderProps } from './types'

export const FiltersProvider = (props: FiltersProviderProps) => {
  const [groupedFilters, dispatch] = useReducer(
    filtersReducerLocalStorage,
    undefined,
    initializeState,
  )

  const addFilter: FiltersContextValue['addFilter'] = useCallback((moduleId, filter) => {
    dispatch({ type: ActionTypes.ADD_FILTER, moduleId: moduleId, filter })
  }, [])

  const addFilters: FiltersContextValue['addFilters'] = useCallback((moduleId, filters) => {
    dispatch({ type: ActionTypes.ADD_FILTERS, moduleId: moduleId, filters })
  }, [])

  const upsertFilters: FiltersContextValue['upsertFilters'] = useCallback((moduleId, filters) => {
    dispatch({ type: ActionTypes.UPSERT_FILTERS, moduleId: moduleId, filters })
  }, [])

  const updateFilter: FiltersContextValue['updateFilter'] = useCallback((moduleId, filter) => {
    dispatch({ type: ActionTypes.UPDATE_FILTER, moduleId: moduleId, filter })
  }, [])

  const removeFilter: FiltersContextValue['removeFilter'] = useCallback((moduleId, filterId) => {
    dispatch({
      type: ActionTypes.REMOVE_FILTER,
      moduleId: moduleId,
      filterId,
    })
  }, [])

  const removeFilters = useCallback((moduleId: string, filtersIds: string[]) => {
    dispatch({
      type: ActionTypes.REMOVE_FILTERS,
      moduleId,
      filtersIds,
    })
  }, [])

  const clearFilters: FiltersContextValue['clearFilters'] = useCallback((moduleId: string) => {
    dispatch({ type: ActionTypes.CLEAR_FILTERS, moduleId: moduleId })
  }, [])

  const contextValue: FiltersContextValue = useMemo(() => {
    return {
      groupedFilters,
      addFilter,
      addFilters,
      upsertFilters,
      updateFilter,
      removeFilter,
      removeFilters,
      clearFilters,
    }
  }, [
    groupedFilters,
    addFilter,
    addFilters,
    upsertFilters,
    updateFilter,
    removeFilters,
    removeFilter,
    clearFilters,
  ])

  return <FiltersContext.Provider value={contextValue}>{props.children}</FiltersContext.Provider>
}
