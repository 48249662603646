import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash-es'
import { api } from '~/lib/api'
import { TENANTS_PERMISSIONS } from '~/lib/queryKeys'
import type {
  ApiResponse,
  Permission,
  Role,
  TenantUserRolesPermissionsResponse,
  TenantUserRolesResponse,
} from '~/types/apiContracts'

async function fetchTenantPermissions(): Promise<TenantUserRolesResponse> {
  const res = (await api.get(`configs/auth/me`).json()) as ApiResponse<TenantUserRolesResponse>
  return res.data
}

export function useTenantUserRolesPermissionsQuery(isAuthenticatedWithTenant: boolean) {
  return useQuery({
    queryKey: [TENANTS_PERMISSIONS],
    queryFn: () => fetchTenantPermissions(),
    enabled: isAuthenticatedWithTenant,
    select: (data): TenantUserRolesPermissionsResponse => {
      if (!data || !data.user.active)
        return { userGlobalId: data.user.global_id, landingUrl: '', permissions: [] }

      const roles = (data.user.roles as Role[]) || []
      let landingUrl = ''
      let currentPriority = 0
      let permissions: Permission[] = []

      roles.forEach((role) => {
        if (role.priority > currentPriority) {
          currentPriority = role.priority
          landingUrl = role.landing_url
        }
        if (role?.permissions) {
          if (role?.permissions) {
            permissions = [...permissions, ...role.permissions]
          }
        }
      })

      const uniquePermissions = uniqBy(permissions, (permission) => permission.code)

      return {
        userGlobalId: data.user.global_id,
        landingUrl,
        permissions: uniquePermissions,
      }
    },
  })
}
