import { replaceTrailingSlashWithDot } from './string'

export const getModuleIdFromPathname = (pathname: string) => {
  if (!pathname) return ''

  const trimmedPathname = pathname.startsWith('/') ? pathname.slice(1) : pathname

  const moduleId = replaceTrailingSlashWithDot(trimmedPathname)

  return moduleId
}
