import { Modal as BaseModal } from '@mui/base'
import type { ModalProps as BaseModalProps } from '@mui/base'
import clsx from 'clsx/lite'
import { ModalCloseButton } from './ModalCloseButton'

// TODO: check later if MUI allows children to be an laso an array of nodes yo we can avoid overwriting the type, not only here but in other places
export type ModalRootProps = Omit<BaseModalProps, 'children'> & {
  children?: React.ReactNode
  renderCloseButton?: boolean
  disablePadding?: boolean
  onMountContentWrapperElement?: (element: HTMLDivElement | null) => void
}

export const ModalRoot = ({
  open = false,
  children,
  className,
  renderCloseButton,
  disablePadding,
  onMountContentWrapperElement,
  ...rest
}: ModalRootProps) => {
  return (
    <BaseModal
      open={open}
      slotProps={{
        backdrop: {
          className: 'bg-backdrop/30 fixed inset-0 z-40',
        },
        root: {
          className:
            'justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none',
        },
      }}
      slots={{
        backdrop: 'div',
      }}
      {...rest}
    >
      <div
        className={clsx(
          'relative z-[500] max-w-full rounded-2xl bg-white',
          !disablePadding && 'p-4',
          className,
        )}
        ref={(el) => {
          onMountContentWrapperElement?.(el)
        }}
      >
        {renderCloseButton && rest.onClose && (
          <ModalCloseButton
            className="absolute right-4 z-[600]"
            // TODO: check later if MUI allows to call onClose without args
            onClick={(event) => rest.onClose?.(event, 'backdropClick')}
          />
        )}
        {children}
      </div>
    </BaseModal>
  )
}
