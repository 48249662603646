import type { ReactNode } from 'react'

interface InfoContentProps {
  title?: string
  details?: ReactNode
}

export const InfoContent = ({ title, details }: InfoContentProps) => {
  return (
    <div className="me-auto flex h-full flex-col justify-center gap-1.5">
      {title && <h2 className="text-body-2">{title}</h2>}
      {details && <p className="text-caption-1">{details}</p>}
    </div>
  )
}
