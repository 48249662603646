import { IconTrash } from '@tabler/icons-react'
import clsx from 'clsx'
import { type ArrayHelpers, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, usePrevious } from 'react-use'
import { useFilterFieldOptionsQuery } from '~/hooks/queries/useFilterFieldOptionsQuery'
import { useFiltersOptionsQuery } from '~/hooks/queries/useFiltersOptionsQuery'
import type { FilterEmpty } from '~/providers/FiltersProvider'
import {
  activeStringOperators,
  mergedFilterOperators,
  numericFiltersOperators,
} from '~/providers/FiltersProvider/helpers'
import type { OptionalClassName } from '~/types'
import type { FilterOptionDatatype } from '~/types/apiContracts'
import { AutocompleteControlled } from '../UI/AutocompleteControlled'
import { IconButton } from '../UI/IconButton'
import { InputControlled } from '../UI/InputControlled'
import { SelectControlled } from '../UI/SelectControlled'
import { SelectOption } from '../UI/SelectOption'
import { getSelectedFieldOptions } from './helpers'

type FilterItemProps = OptionalClassName & {
  moduleId: string
  filter: FilterEmpty
  index: number
  totalFilters: number
  arrayHelpers: ArrayHelpers
  clearAllFilters: () => void
}

export const FilterItem = (props: FilterItemProps) => {
  const { setFieldValue } = useFormikContext()
  const { t } = useTranslation(['common', 'filter'])
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('')
  const [debouncedAutocompleteInputValue, setDebouncedAutocompleteInputValue] = useState('')

  const { filter, index, arrayHelpers } = props

  const filtersOptionsQuery = useFiltersOptionsQuery(props.moduleId)

  const fieldOptions = (filtersOptionsQuery.data?.filters || []).map((filter) => {
    return {
      ...filter,
      value: filter.field_name,
      // @ts-expect-error dynamic label
      label: t(`${filter.default_label}`) as string,
    }
  })

  const prevFilterFieldValue = usePrevious(filter.field)

  useEffect(() => {
    // if filter field changes
    if (prevFilterFieldValue && prevFilterFieldValue !== filter.field) {
      // reset filter value
      setFieldValue(`filters.${index}.value`, undefined)
      setFieldValue(`filters.${index}.operator`, 'in')
      setAutocompleteInputValue('')
    }
  }, [filter.field, index, prevFilterFieldValue, setFieldValue])

  const selectedField = fieldOptions.find((option) => option.value === filter.field)
  const datatypeFieldSelected = selectedField?.datatype as FilterOptionDatatype

  const isNumericField = datatypeFieldSelected === 'NUMERIC'

  const operatorOptions = mergedFilterOperators
    .filter((operator) => {
      if (isNumericField) {
        return [...numericFiltersOperators, ...activeStringOperators].includes(operator)
      }

      return activeStringOperators.includes(operator)
    })
    .map((operator) => ({
      label: t(`operator.${operator}`),
      value: operator,
    }))

  const filterFieldOptionsQuery = useFilterFieldOptionsQuery({
    moduleId: props.moduleId,
    fieldName: selectedField?.field_name as string,
    search: debouncedAutocompleteInputValue,
    enabled:
      !!selectedField?.field_name && !['NUMERIC', 'WEEK_DAYS'].includes(datatypeFieldSelected),
  })

  const selectedFieldOptions = getSelectedFieldOptions(
    datatypeFieldSelected,
    filterFieldOptionsQuery?.data?.options || [],
  )

  useDebounce(
    () => {
      setDebouncedAutocompleteInputValue(autocompleteInputValue)
    },
    500,
    [autocompleteInputValue],
  )

  // TODO: keep this line and add the necessary operators here when they are ready in the back
  const shouldShowValueField = ['in', 'not in', 'greater than', 'less than'].includes(
    filter.operator!,
  )

  return (
    <div
      className={clsx(
        'flex flex-col gap-4 px-5 py-8 @2xl:flex-row @2xl:items-center md:pe-6 md:ps-10',
        props.className,
      )}
    >
      <div className="min-w-[28%]">
        <SelectControlled
          name={`filters.${index}.field`}
          placeholder={t('field')}
          className="w-full"
          slotProps={{
            popup: {
              className: 'z-[510]',
            },
          }}
          onListboxOpenChange={() => {
            setAutocompleteInputValue('')
          }}
          size="large"
        >
          {fieldOptions.map((option, i) => (
            <SelectOption key={`${option.value}-${i}`} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectControlled>
      </div>
      <div className="min-w-[24%]">
        <SelectControlled
          name={`filters.${index}.operator`}
          placeholder={t('condition')}
          disabled={!filter.field}
          className="w-full"
          size="large"
        >
          {operatorOptions.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectControlled>
      </div>
      <div className="flex-grow">
        {shouldShowValueField &&
          (isNumericField ? (
            // TODO: validate why, even though the number input has the same height as the selects with the same class, they do not have the same height on the screen
            <InputControlled
              format="number"
              name={`filters.${index}.value`}
              autoComplete="off"
              size="large"
              placeholder={t('value')}
            />
          ) : (
            <AutocompleteControlled
              id={`filters.${index}.value`}
              name={`filters.${index}.value`}
              options={selectedFieldOptions}
              placeholder={t('options')}
              multiple
              inputValue={autocompleteInputValue}
              onInputChange={(_, inputValue) => {
                setAutocompleteInputValue(inputValue)
              }}
              clearOnBlur={false}
              popupClassName="z-[510]"
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disabled={!filter.field || !filter.operator}
              isLoading={filterFieldOptionsQuery?.isFetching}
              size="large"
            />
          ))}
      </div>
      <IconButton
        onClick={() => {
          if (props.totalFilters === 1) {
            props.clearAllFilters()
          } else {
            arrayHelpers.remove(index)
          }
        }}
        title={t('action.remove')}
        className="self-center"
      >
        <IconTrash size={20} />
      </IconButton>
    </div>
  )
}
