import { useFieldControlled } from '~/hooks/useFieldControlled'
import { Input } from '../Input'
import type { InputControlledProps } from './types'

export const InputControlled = ({ name, format, ...props }: InputControlledProps) => {
  const [field] = useFieldControlled(name, format)

  // TODO: fix type later
  // @ts-expect-error Couldn't find a way to make this work without ts-expect-error
  return <Input {...field} {...props} />
}
