import type { GridColDef, GridInitialState } from '@mui/x-data-grid-premium'
import { DataGridPremium, gridPinnedColumnsSelector, useGridApiRef } from '@mui/x-data-grid-premium'
import clsx from 'clsx/lite'
import { difference, isEmpty } from 'lodash-es'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import { NoData } from '~/components/NoData'
import { arraysHaveSameItems, moveArrayElement } from '~/utils/array'
import { omit } from '~/utils/general'
import { Loader } from '../Loader'
import { DataTablePagination } from './DataTablePagination'
import { DataTableRowsSekeleton } from './DataTableRowsSekeleton'
import { DataTableToolbar } from './DataTableToolbar'
import { DataTableTranslation } from './DataTableTranslation'
import {
  checkIfColumnsAreEmpty,
  consolidateColumnFields,
  consolidateColumnWidths,
  getDataGridStateFromLocalStorage,
  mapColumnWidths,
  saveDataGridStateToLocalStorage,
} from './helpers'
import { baseStylesSx } from './styles'
import type { DataTableProps, DataTableToolbarOwnProps } from './types'
import { useDataTableScrollAnimation } from './useDataTableScrollAnimation'

// augment the props for the toolbar slot
declare module '@mui/x-data-grid-premium' {
  interface ToolbarPropsOverrides extends DataTableToolbarOwnProps {}
}

export const DataTable = (props: DataTableProps) => {
  if (!props.tableId) {
    throw new Error('tableId prop cannot be empty')
  }
  const apiLocalRef = useGridApiRef()

  const columns = props.columns

  const columnsFields = useMemo(() => columns.map((col) => col.field), [columns])
  const [columnFieldsOrdered, setColumnFieldsOrdered] = useLocalStorage(
    `datatable-column-fields-ordered.${props.tableId}`,
    columns.map((col) => col.field),
  )
  const [columnWidths, setColumnWidths] = useLocalStorage(
    `datatable-column-widths.${props.tableId}`,
    mapColumnWidths(columns),
  )

  const [initialState, setInitialState] = useState<GridInitialState | undefined>(
    // if table state is going to load from localStorage, we will make it undefined until we load it
    props.enableStatePersistence ? undefined : props.initialState || {},
  )
  const hasLoadedInitalState = !!initialState

  const dataGridProps = omit(
    props,
    'tableId',
    'enableStatePersistence',
    'rootClassName',
    'tableContainerClassName',
    'isLoadingFirstTime',
    'hideExportDialogColumnsSection',
    'hideExportDialogDateRangeSection',
    'exportButtonProps',
    'extraContent',
  )

  const { t } = useTranslation()

  const apiRef = props.apiRef || apiLocalRef
  const areColumnsEmpty = checkIfColumnsAreEmpty(columns)

  useDataTableScrollAnimation()

  useEffect(() => {
    if (areColumnsEmpty) return

    if (columnFieldsOrdered && arraysHaveSameItems(columnFieldsOrdered, columnsFields)) return

    // in case the number of column fields differ, generate the orderedColumnFields again
    const newColumnFieldsOrdered = columnFieldsOrdered
      ? consolidateColumnFields(columnFieldsOrdered, columnsFields)
      : columnsFields
    const newColumnWidths = columnWidths
      ? consolidateColumnWidths(columnWidths, mapColumnWidths(columns))
      : mapColumnWidths(columns)

    setColumnFieldsOrdered(newColumnFieldsOrdered)
    setColumnWidths(newColumnWidths)
  }, [
    areColumnsEmpty,
    columnFieldsOrdered,
    columns,
    columnWidths,
    columnsFields,
    setColumnFieldsOrdered,
    setColumnWidths,
  ])

  const mappedColumns = useMemo(() => {
    if (!columnFieldsOrdered || !columnWidths) return columns

    const mappedColumns = columnFieldsOrdered.reduce<GridColDef[]>((arr, field) => {
      let column = columns.find((col) => col.field === field)

      if (!column) return arr

      const columnWidth = columnWidths[column.field]

      if (columnWidth != null) {
        const originalCellClassName = column.cellClassName
        column = {
          ...column,
          width: columnWidth,
          cellClassName: (params) => {
            const isCellClassNameFunction = typeof originalCellClassName === 'function'

            const className = isCellClassNameFunction
              ? originalCellClassName(params)
              : originalCellClassName

            return clsx(className, column?.type === 'number' && 'text-caption-2')
          },
        }

        delete column.flex
      }

      arr.push(column)

      return arr
    }, [])

    return mappedColumns
  }, [columnFieldsOrdered, columnWidths, columns])

  const saveStateToLocalStorage = useCallback(() => {
    saveDataGridStateToLocalStorage(props.tableId, apiRef)
  }, [props.tableId, apiRef])

  useLayoutEffect(() => {
    if (!props.enableStatePersistence) return

    if (!hasLoadedInitalState) {
      const stateFromLocalStorage = getDataGridStateFromLocalStorage(props.tableId)

      const newInitialState = stateFromLocalStorage || props.initialState || {}

      // This ensures that the change of pinnedColumns is reactive
      setInitialState({
        ...newInitialState,
        pinnedColumns: newInitialState.pinnedColumns || props.initialState?.pinnedColumns,
      })
      return
    }

    window.addEventListener('beforeunload', saveStateToLocalStorage)

    return () => {
      window.removeEventListener('beforeunload', saveStateToLocalStorage)
      saveStateToLocalStorage()
    }
  }, [
    hasLoadedInitalState,
    props.initialState,
    props.tableId,
    saveStateToLocalStorage,
    props.enableStatePersistence,
  ])

  const hasAnyAggregation = !isEmpty(props.aggregationModel)

  if (!hasLoadedInitalState) {
    // TODO: replace with table loading skeleton?
    return <Loader />
  }

  const hasPagination = props.pagination

  return (
    <>
      {(!!props.rows?.length || props.loading) && (
        <div className={clsx('flex h-full flex-col gap-7 overflow-auto', props.rootClassName)}>
          <div
            className={clsx(
              'min-h-[300px] rounded-lg border border-night-100 bg-white px-6 py-2 shadow-card',
              hasPagination ? 'h-[calc(100%-36px-28px)]' : 'h-full', // 36px → pagination comp. height, 28px → space between the table and the pagination comp.
              props.tableContainerClassName,
            )}
          >
            {
              <DataGridPremium
                {...dataGridProps}
                apiRef={apiRef}
                slots={{
                  toolbar: DataTableToolbar,
                  loadingOverlay: props.isLoadingFirstTime ? DataTableRowsSekeleton : undefined,
                  ...props.slots,
                }}
                localeText={{
                  ...props.localeText,
                  ...DataTableTranslation(t),
                }}
                slotProps={{
                  toolbar: {
                    hideExportDialogColumnsSection: props.hideExportDialogColumnsSection,
                    hideExportDialogDateRangeSection: props.hideExportDialogDateRangeSection,
                    exportButtonProps: props.exportButtonProps,
                    extraContent: props.extraContent,
                  },
                  footer: {
                    style: {
                      minHeight: '24px',
                      borderTop: 'none',
                    },
                  },
                  ...props.slotProps,
                }}
                initialState={initialState}
                sx={{
                  ...baseStylesSx,
                  ...props.sx,
                }}
                classes={{
                  root: 'overflow-hidden !border-0',
                  main: 'text-caption-1',
                  columnHeaderTitle: '!text-caption-1',
                  columnHeaderTitleContainerContent: 'text-night-600',
                  virtualScrollerContent: 'text-night-700',
                }}
                scrollbarSize={0}
                columns={mappedColumns}
                rowHeight={42}
                columnHeaderHeight={hasAnyAggregation ? 44 : 32}
                hideFooterPagination
                loading={props.isLoadingFirstTime || props.loading}
                onColumnOrderChange={(params) => {
                  if (!columnFieldsOrdered) return

                  const oldIndex = props.checkboxSelection ? params.oldIndex - 1 : params.oldIndex
                  const targetIndex = props.checkboxSelection
                    ? params.targetIndex - 1
                    : params.targetIndex

                  const pinnedColumns = gridPinnedColumnsSelector(apiRef.current.state)
                  const rightPinnedColumns = pinnedColumns.right
                  const hasRightPinnedColumns = rightPinnedColumns && rightPinnedColumns.length > 0

                  const columnFieldsOrderedClone = hasRightPinnedColumns
                    ? // strip right pinned columns out to prevent bug affecting the ordering of the columns
                      difference(columnFieldsOrdered, rightPinnedColumns)
                    : [...columnFieldsOrdered]

                  const newColumnFieldsOrdered = moveArrayElement(
                    columnFieldsOrderedClone,
                    oldIndex,
                    targetIndex,
                  )

                  if (hasRightPinnedColumns) {
                    // re-add right pinned columns to the end of the newColumnFieldsOrdered
                    rightPinnedColumns.forEach((rightPinnedColumn) => {
                      newColumnFieldsOrdered.push(rightPinnedColumn)
                    })
                  }

                  setColumnFieldsOrdered(newColumnFieldsOrdered)
                }}
                onColumnWidthChange={(params) => {
                  setColumnWidths({
                    ...columnWidths,
                    [params.colDef.field]: params.width,
                  })
                }}
                disableMultipleColumnsSorting={dataGridProps.disableMultipleColumnsSorting ?? true}
              />
            }
          </div>
          {hasPagination && <DataTablePagination apiRef={apiRef} rootProps={props} />}
        </div>
      )}
      {!props.rows?.length && !props.loading && (
        <div className="h-full rounded-lg border border-night-100 bg-white px-6 py-2 shadow-card">
          <NoData />
        </div>
      )}
    </>
  )
}
