import type { DatasetColumn } from '~/types/apiContracts'
import type { numericFilterOperatorsMap, stringFilterOperatorsMap } from './helpers'

export type FiltersProviderProps = {
  children: React.ReactNode
}

export type FiltersContextValue = {
  groupedFilters: FiltersGroupedByModule
  addFilter: (moduleId: string, filter: Filter) => void
  addFilters: (moduleId: string, filters: Filter[]) => void
  upsertFilters: (moduleId: string, filters: Filter[]) => void
  updateFilter: (moduleId: string, filter: Filter) => void
  removeFilter: (moduleId: string, filterId: Filter['id']) => void
  removeFilters: (moduleId: string, filtersIds: string[]) => void
  clearFilters: (moduleId: string) => void
}

export type ProcessedFilter = Pick<Filter, 'field' | 'operator'> & {
  value: string[] | string | number
}

export type PreProcessedDateFilters = [] | [Filter, Filter] | [Filter, Filter, Filter, Filter]

export type ProcessedDateFilters = {
  main: {
    start: string // format: 'YYYY-MM-DD'
    end: string
  }
  comparison?: {
    start: string
    end: string
  }
}

export type FiltersContextModuleValue = {
  filters: Filter[]
  filtersById: FiltersById
  dateFilters: Filter[]
  basicFilters: Filter[]
  // TODO: refactor value types
  processedFilters: ProcessedFilter[]
  processedDateFilters: ProcessedDateFilters | undefined
  addFilter: (filter: Filter) => void
  addFilters: (filter: Filter[]) => void
  upsertFilters: (filter: Filter[]) => void
  updateFilter: (filter: Filter) => void
  removeFilter: (filter: Filter['id']) => void
  removeFilters: (filtersIds: string[]) => void
  clearFilters: () => void
}

export type FiltersGroupedByModule = {
  [moduleId: string]: FiltersById
}

export type FiltersById = {
  [filterKey: string]: Filter
}

export type Filter = {
  id: string
  field: string
  queryType?: 'where' | 'having'
} & ( // TODO: refactor this to use only array types
  | FilterValueBoolean
  | FilterValueBase<'string'>
  | FilterValueBase<'number'>
  | FilterValueBase<'date'>
  | FilterValueBase<'array'>
  | FilterValueBetween<'string'>
  | FilterValueBetween<'number'>
  | FilterValueBetween<'date'>
  | FilterValueBetween<'array'>
  | FilterValueInOrNotIn<'string'>
  | FilterValueInOrNotIn<'number'>
  | FilterValueInOrNotIn<'date'>
  | FilterValueInOrNotIn<'array'>
  | FiltersValueIsNullOrNotNull
)

export type FilterArrayValue = {
  value: string
  label: string
}

export type FilterShort = {
  field: string
  operator: FilterOperatorValues
  value: string[] | string
}

export type FilterEmpty = Partial<Filter>

export type FilterConfig = Partial<Filter> & {
  field: string | DatasetColumn
}

type FilterValueTypesLookup = {
  string: string
  number: number
  date: Date
  array: FilterArrayValue[]
}

type FilterValueTypes = keyof FilterValueTypesLookup

export type FilterOperator = typeof stringFilterOperatorsMap & typeof numericFilterOperatorsMap
export type FilterOperatorValues = FilterOperator[keyof FilterOperator]

type FilterValueBoolean = {
  operator: FilterOperator['is'] | FilterOperator['is not']
  valueType: 'boolean'
  value: boolean
}

type FilterValueBase<ValueType extends FilterValueTypes> = {
  operator:
    | FilterOperator['is']
    | FilterOperator['is not']
    | FilterOperator['greater than']
    | FilterOperator['greaterOrEqual']
    | FilterOperator['greater than']
    | FilterOperator['lessOrEqual']
  valueType: ValueType
  value: FilterValueTypesLookup[ValueType]
}

type FilterValueBetween<ValueType extends FilterValueTypes> = {
  operator: FilterOperator['between']
  valueType: ValueType
  value: [FilterValueTypesLookup[ValueType], FilterValueTypesLookup[ValueType]]
}

type FilterValueInOrNotIn<ValueType extends FilterValueTypes> = {
  operator: FilterOperator['in'] | FilterOperator['not in']
  valueType: ValueType
  value: FilterValueTypesLookup[ValueType][]
}

type FiltersValueIsNullOrNotNull = {
  operator: FilterOperator['is null'] | FilterOperator['is not null']
  valueType: never
  value: never
}

export enum ActionTypes {
  ADD_FILTER = 'ADD_FILTER',
  ADD_FILTERS = 'ADD_FILTERS',
  UPSERT_FILTERS = 'UPSERT_FILTER',
  UPDATE_FILTER = 'UPDATE_FILTER',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_FILTERS = 'REMOVE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}

type AddFilterAction = {
  type: ActionTypes.ADD_FILTER
  moduleId: string
  filter: Filter
}

type AddFiltersAction = {
  type: ActionTypes.ADD_FILTERS | ActionTypes.UPSERT_FILTERS
  moduleId: string
  filters: Filter[]
}

type UpdateFilterAction = {
  type: ActionTypes.UPDATE_FILTER
  moduleId: string
  filter: Filter
}

type RemoveFilterAction = {
  type: ActionTypes.REMOVE_FILTER
  moduleId: string
  filterId: string
}

type RemoveFiltersAction = {
  type: ActionTypes.REMOVE_FILTERS
  moduleId: string
  filtersIds: string[]
}

type ClearFiltersAction = {
  type: ActionTypes.CLEAR_FILTERS
  moduleId: string
}

export type Action =
  | AddFilterAction
  | AddFiltersAction
  | UpdateFilterAction
  | RemoveFilterAction
  | RemoveFiltersAction
  | ClearFiltersAction
