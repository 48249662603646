import type React from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '../UI/Button/LinkButton'

type ErrorScreenProps = {
  image: React.ReactNode
  title: string
  subtitle: string
}

export const ErrorScreen = (props: ErrorScreenProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full flex-col items-center justify-center bg-seasalt p-6 text-center">
      {props.image && <div className="mb-[90px]">{props.image}</div>}

      <h1 className="text-title-1 rounded-[10px] bg-white px-16 py-2.5 text-night-800">
        {props.title}
      </h1>

      <p className="text-subtitle-1 mt-9 max-w-[540px] text-balance text-night-700">
        {props.subtitle}
      </p>

      <LinkButton variant="primary" className="mt-16" to="/">
        {t('goHome')}
      </LinkButton>
    </div>
  )
}
