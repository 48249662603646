import { createFileRoute } from '@tanstack/react-router'
import { redirectIfPathnamesMatch } from '~/utils/routing'

const routePath = '/planning' as const

export const Route = createFileRoute(`/_layout${routePath}/`)({
  beforeLoad: ({ location }) => {
    redirectIfPathnamesMatch({
      pathname: location.pathname,
      expectedPathname: routePath,
      redirectTo: `${routePath}/demand-and-promotions`,
    })
  },
})
