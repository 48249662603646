import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import type { DehydrateOptions } from '@tanstack/react-query'
import { QueryClient, defaultShouldDehydrateQuery } from '@tanstack/react-query'
import { TimeoutError } from 'ky'
import { toast } from '~/components/UI/Toast'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 12, // 12 hours
    },
    mutations: {
      onError: (error) => {
        // TODO: I think that maybe this should be done in the ky request level, but beforeError hook doesn't run if the error is a TimeoutError, check later if that's get fixed. see: https://github.com/sindresorhus/ky/issues/508
        if (error instanceof TimeoutError) {
          toast({ content: 'Request timed out', status: 'error' })
        }
      },
    },
  },
})

export const localStoragePersister = createSyncStoragePersister({
  storage: localStorage,
  key: 'queryClient',
})

export const dehydrateOptions: DehydrateOptions = {
  shouldDehydrateQuery: (query) => {
    // Dehydrate all queries except those with meta cache: false
    // IOW, not persist/cache those queries.
    // taKen from https://github.com/TanStack/query/discussions/4833#discussioncomment-5625341
    return defaultShouldDehydrateQuery(query) && query.meta?.cache !== false
  },
}
