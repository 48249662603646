import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'

const boardSearch = z.object({
  key_product: z.string(),
  key_warehouse: z.string().optional(),
  key_distribution_warehouse: z.string().optional(),
})

export const Route = createFileRoute('/_layout/work-area/procurement/dashboard/')({
  validateSearch: zodSearchValidator(boardSearch),
})
