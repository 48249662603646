import Bugsnag from '@bugsnag/js'
import { ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { RouterProvider } from '@tanstack/react-router'
import { setDefaultOptions } from 'date-fns'
import { es } from 'date-fns/locale/es'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMixpanelInit } from './hooks/useMixpanelInit'
import './index.css'
import './lib/firebase'
import './lib/i18n'
import { dehydrateOptions, localStoragePersister, queryClient } from './lib/queryClient'
import { router } from './lib/tanstackRouter'
import { AuthProvider, useAuthContext } from './providers/AuthProvider'
import { FiltersProvider } from './providers/FiltersProvider'
import { FullScreenProvider } from './providers/FullScreenProvider'
import { muiTheme } from './theme/muiTheme'

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE)

export const App = () => {
  const { i18n } = useTranslation()

  useMixpanelInit()

  useEffect(() => {
    /**
     * When something changes in the code and a new build is generated and deployed,
     * the generated chunks of the built app will have a new hash in their name (like index-n3d7HZ3w.js)
     * and the browser will try to load the old chunks that are not available anymore
     * and will throw a error.
     *
     * We handle this error by reloading the page. Taken from: https://github.com/TanStack/router/issues/1276#issuecomment-1989333413
     */
    const handlePreloadError = () => {
      window.location.reload()
    }

    window.addEventListener('vite:preloadError', handlePreloadError)

    return () => {
      window.removeEventListener('vite:preloadError', handlePreloadError)
    }
  }, [])

  useEffect(() => {
    // set default locale options for date-fns globally
    // this will set options like startOfWeek to 1 (Monday)
    setDefaultOptions({ locale: i18n.language === 'es' ? es : undefined })
    Bugsnag.addMetadata('i18n', {
      language: i18n.language,
    })
  }, [i18n.language])

  return (
    <PersistQueryClientProvider
      persistOptions={{
        persister: localStoragePersister,
        dehydrateOptions,
      }}
      client={queryClient}
    >
      <ThemeProvider theme={muiTheme}>
        <Toaster />
        <AuthProvider>
          <FullScreenProvider>
            <FiltersProvider>
              <RouteProviderWithAuthContext />
            </FiltersProvider>
          </FullScreenProvider>
        </AuthProvider>
        <ReactQueryDevtools />
      </ThemeProvider>
    </PersistQueryClientProvider>
  )
}

const RouteProviderWithAuthContext = () => {
  const auth = useAuthContext()

  return <RouterProvider router={router} context={{ auth }} />
}
