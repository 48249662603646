import { useContext } from 'react'
import { DateRangePickerFilterContext } from './DateRangePickerFilterContext'

export const useDateRangePickerFilterContext = () => {
  const context = useContext(DateRangePickerFilterContext)
  if (!context) {
    throw new Error('useDateRangePickerFilterContext must be used within a DateRangePickerContext')
  }
  return context
}
