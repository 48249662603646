import type { OptionalClassName } from '~/types'

export const IconUnitedStates = ({ className }: OptionalClassName) => {
  return (
    <svg
      width="14"
      className={className}
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="#F2F0F2" />
      <mask id="mask0_19_5295" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <circle cx="7" cy="7" r="7" fill="#FCFCFC" />
      </mask>
      <g mask="url(#mask0_19_5295)">
        <rect x="2.2749" y="12.425" width="9.625" height="1.75" fill="#D90026" />
        <rect x="2.2749" y="12.425" width="9.625" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="8.92499" width="13.825" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="8.92499" width="13.825" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="5.25" width="13.825" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="5.25" width="13.825" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="1.75" width="13.825" height="1.75" fill="#D90026" />
        <rect x="0.174805" y="1.75" width="13.825" height="1.75" fill="#D90026" />
        <rect width="7.175" height="7" fill="#0052B5" />
        <path
          d="M5.7752 5.86248L5.2502 6.12498L5.4252 5.59998L5.0752 5.24998H5.6002L5.7752 4.72498L6.0377 5.24998H6.4752L6.1252 5.59998L6.3002 6.12498L5.7752 5.86248Z"
          fill="#EFEFEF"
        />
        <path
          d="M3.325 5.86248L2.8 6.12498L2.975 5.59998L2.625 5.24998H3.15L3.325 4.72498L3.5875 5.24998H4.025L3.675 5.59998L3.85 6.12498L3.325 5.86248Z"
          fill="#EFEFEF"
        />
        <path
          d="M0.874805 5.86248L0.349805 6.12498L0.524805 5.59998L0.174805 5.24998H0.699805L0.874805 4.72498L1.1373 5.24998H1.5748L1.2248 5.59998L1.3998 6.12498L0.874805 5.86248Z"
          fill="#EFEFEF"
        />
        <path
          d="M5.7752 3.93749L5.2502 4.19999L5.4252 3.67499L5.0752 3.32499H5.6002L5.7752 2.79999L6.0377 3.32499H6.4752L6.1252 3.67499L6.3002 4.19999L5.7752 3.93749Z"
          fill="#EFEFEF"
        />
        <path
          d="M5.7752 2.0125L5.2502 2.275L5.4252 1.75L5.0752 1.4H5.6002L5.7752 0.875L6.0377 1.4H6.4752L6.1252 1.75L6.3002 2.275L5.7752 2.0125Z"
          fill="#EFEFEF"
        />
        <path
          d="M5.7752 0.0875121L5.2502 0.350012L5.4252 -0.174988L5.0752 -0.524988H5.6002L5.7752 -1.04999L6.0377 -0.524988H6.4752L6.1252 -0.174988L6.3002 0.350012L5.7752 0.0875121Z"
          fill="#EFEFEF"
        />
        <path
          d="M3.325 2.0125L2.8 2.275L2.975 1.75L2.625 1.4H3.15L3.325 0.875L3.5875 1.4H4.025L3.675 1.75L3.85 2.275L3.325 2.0125Z"
          fill="#EFEFEF"
        />
        <path
          d="M3.325 3.93749L2.8 4.19999L2.975 3.67499L2.625 3.32499H3.15L3.325 2.79999L3.5875 3.32499H4.025L3.675 3.67499L3.85 4.19999L3.325 3.93749Z"
          fill="#EFEFEF"
        />
        <path
          d="M0.874805 3.93749L0.349805 4.19999L0.524805 3.67499L0.174805 3.32499H0.699805L0.874805 2.79999L1.1373 3.32499H1.5748L1.2248 3.67499L1.3998 4.19999L0.874805 3.93749Z"
          fill="#EFEFEF"
        />
      </g>
    </svg>
  )
}
