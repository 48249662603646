import type { GridColDef } from '@mui/x-data-grid-premium'
import { sortBy } from 'lodash-es'
import type { ApiColDef } from '~/types/apiContracts'

function getColumnName(apiColDef: ApiColDef): string {
  return apiColDef.name
}

export function getColumnNames(apiColDefs: ApiColDef[] = []): string[] {
  return apiColDefs.map(getColumnName)
}

export function getColHeaderName(colDef: ApiColDef) {
  return colDef.label
}

/**
 * Check if the column is of 'KEY' type.
 */
function isKeyApiColumn(apiColumn: ApiColDef): boolean {
  return apiColumn.type === 'KEY'
}

/**
 * Filter only the columns with `visible` property set to true.
 */
export function filterVisibleApiColumns(apiColDefs: ApiColDef[]): ApiColDef[] {
  return apiColDefs.filter((col) => col.visible)
}

/**
 * Filter only the columns with 'KEY' type.
 */
export function filterKeyApiColumns(colDefs: ApiColDef[] = []) {
  return colDefs.filter((col) => isKeyApiColumn(col))
}

/**
 * Get the names of the columns with 'KEY' type.
 */
export function getKeyApiColumnNames(colDefs: ApiColDef[] = []): string[] {
  return getColumnNames(filterKeyApiColumns(colDefs))
}

/**
 * Get the names of the columns with 'KEY' type and `visible` property set to true.
 */
export function filterKeyVisibleApiColumns(colDefs: ApiColDef[] = []): ApiColDef[] {
  return colDefs.filter((col) => isKeyApiColumn(col) && col.visible)
}

export function isFormatNumber(format: ApiColDef['format']) {
  return format === 'INT64' || format === 'FLOAT64'
}

const apiColDataTypeMap: Record<ApiColDef['data_type'], GridColDef['type']> = {
  INT64: 'number',
  FLOAT64: 'number',
  BOOL: 'boolean',
  // TODO: validate this date type in the next API version
  DATE: 'string',
  STRING: 'string',
  TIMESTAMP: 'dateTime',
  PERCENTAGE: 'number',
  NUMERIC: 'number',
} as const

export function isDataTypeNumber(dataType: ApiColDef['data_type']) {
  return apiColDataTypeMap[dataType] === 'number'
}

export function getColDefType(apiColType: ApiColDef['data_type']): GridColDef['type'] {
  return apiColDataTypeMap[apiColType]
}

function isNumericColumn(col: ApiColDef) {
  return isFormatNumber(col.format) || isDataTypeNumber(col.data_type)
}

export function isNumericNotKeyApiColumn(col: ApiColDef) {
  return col.type !== 'KEY' && isNumericColumn(col)
}

/**
 * Filter only the columns with numeric `data_type` and not 'KEY' type.
 */
export function filterNumericNotKeyApiColumns(colDefs: ApiColDef[] = []): ApiColDef[] {
  return colDefs.filter(isNumericNotKeyApiColumn)
}

export function getColDefAlign(col: ApiColDef): GridColDef['align'] {
  return isNumericColumn(col) ? 'right' : 'left'
}

export function sortColumns(columns: ApiColDef[]) {
  return sortBy(columns, [(column) => Number(column.group_order), (column) => Number(column.order)])
}

export function findColumnWithSuggestedInputValue<T extends ApiColDef>(
  columns?: T[],
): T | undefined {
  return columns?.find((column) => column.input_value)
}
