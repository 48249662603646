import type { SelectRootSlotProps as BaseSelectRootSlotProps } from '@mui/base'
import { IconChevronDown } from '@tabler/icons-react'
import { forwardRef } from 'react'

export const SelectButton = forwardRef(function SelectButton<
  TValue extends object,
  Multiple extends boolean,
>(props: BaseSelectRootSlotProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
  const { ownerState, ...other } = props
  const isOpen = ownerState.open

  return (
    <button type="button" {...other} ref={ref}>
      <span className="flex-grow truncate text-left"> {other.children} </span>
      <IconChevronDown
        className={`ms-auto size-5 flex-shrink-0 text-neutral-500 transition-all duration-200 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
      />
    </button>
  )
})
