import type { DateRange } from '@mui/x-date-pickers-pro'
import { hasIn, isEmpty, isEqual } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import type { Filter } from '~/providers/FiltersProvider'
import { useFilters } from '~/providers/FiltersProvider'
import type { SettedDates } from '../types'
import { DateRangePickerFilterContext } from './DateRangePickerFilterContext'
import type { DateRangePickerFilterContextValue, DateRangePickerFilterProviderProps } from './types'

export const DateRangePickerFilterProvider = ({
  defaultValues,
  moduleId,
  children,
}: DateRangePickerFilterProviderProps) => {
  const { addFilters, dateFilters, removeFilters } = useFilters(moduleId)

  const settedDates: SettedDates = dateFilters.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.field]: {
        id: filter.id,
        value: filter.value,
      },
    }
  }, {})

  const hasSettedDates = !isEmpty(settedDates)
  const hasSettedComparisonDates = hasIn(settedDates, 'comparisonStart')

  const settedMainDateRange: DateRange<Date> = [
    settedDates?.mainStart?.value,
    settedDates?.mainEnd?.value,
  ]

  const comparisonRangeDates: DateRange<Date> = [
    settedDates?.comparisonStart?.value,
    settedDates?.comparisonEnd?.value,
  ]

  const [mainDateRange, setMainDateRange] = useState(
    hasSettedDates ? settedMainDateRange : defaultValues.main,
  )

  const [comparisonDateRange, setComparisonDateRange] = useState(
    hasSettedComparisonDates ? comparisonRangeDates : defaultValues.comparison,
  )

  const isEmptyFilters = isEmpty(dateFilters)

  const [periodSelected, setPeriodSelected] = useState(defaultValues.main)

  const generateMainDateFiltersFields = useCallback(
    (dateRange: Date[]): Filter[] => {
      const [startDate, endDate] = dateRange

      return [
        {
          field: 'mainStart',
          id: settedDates?.mainStart?.id || uuidv4(),
          value: startDate,
          operator: 'is',
          valueType: 'date',
        },
        {
          field: 'mainEnd',
          id: settedDates?.mainEnd?.id || uuidv4(),
          value: endDate,
          operator: 'is',
          valueType: 'date',
        },
      ]
    },
    [settedDates?.mainStart?.id, settedDates?.mainEnd?.id],
  )

  const saveMainDateRange = useCallback(
    (dateRange: DateRange<Date>) => {
      addFilters(generateMainDateFiltersFields(dateRange as Date[]))
      setMainDateRange(dateRange)
    },
    [addFilters, generateMainDateFiltersFields],
  )

  const saveComparisonDateRange = useCallback(
    (dateRange: DateRange<Date>) => {
      const [comparisonStart, comparisonEnd] = dateRange as Date[]

      addFilters([
        ...generateMainDateFiltersFields(mainDateRange as Date[]),
        {
          field: 'comparisonStart',
          id: settedDates?.comparisonStart?.id || uuidv4(),
          value: comparisonStart,
          operator: 'is',
          valueType: 'date',
        },
        {
          field: 'comparisonEnd',
          id: settedDates?.comparisonEnd?.id || uuidv4(),
          value: comparisonEnd || comparisonStart,
          operator: 'is',
          valueType: 'date',
        },
      ])
      setComparisonDateRange(dateRange)
    },
    [
      addFilters,
      mainDateRange,
      generateMainDateFiltersFields,
      settedDates?.comparisonStart?.id,
      settedDates?.comparisonEnd?.id,
    ],
  )

  const hasDefaultDatesSetted =
    isEqual(defaultValues.main, mainDateRange) &&
    isEqual(defaultValues.comparison, comparisonDateRange)

  const resetInternalFilters = useCallback(() => {
    setMainDateRange(defaultValues.main)
    setComparisonDateRange(defaultValues.comparison)
  }, [defaultValues.main, defaultValues.comparison])

  const resetDateFilters = useCallback(() => {
    removeFilters(dateFilters.map((filter) => filter.id))
    resetInternalFilters()
  }, [resetInternalFilters, removeFilters, dateFilters])

  const resetComparisonDates = useCallback(() => {
    removeFilters([settedDates.comparisonStart.id, settedDates.comparisonEnd.id])
    setComparisonDateRange(defaultValues.comparison)
  }, [
    defaultValues.comparison,
    removeFilters,
    settedDates.comparisonStart?.id,
    settedDates.comparisonEnd?.id,
  ])

  const savePeriodSelected = useCallback(
    (period: DateRange<Date>) => {
      setPeriodSelected(period)
      setMainDateRange(period)
      addFilters(generateMainDateFiltersFields(period as Date[]))
    },
    [setPeriodSelected, addFilters, generateMainDateFiltersFields],
  )

  useEffect(() => {
    if (isEmptyFilters && !hasDefaultDatesSetted) {
      resetInternalFilters()
    }
  }, [resetInternalFilters, isEmptyFilters, hasDefaultDatesSetted])

  const contextValue: DateRangePickerFilterContextValue = useMemo(() => {
    return {
      values: {
        main: mainDateRange,
        comparison: comparisonDateRange,
        period: periodSelected,
      },
      defaultValues,
      hasSettedDates,
      resetComparisonDates,
      savePeriodSelected,
      resetDateFilters,
      saveComparisonDateRange,
      saveMainDateRange,
    }
  }, [
    periodSelected,
    comparisonDateRange,
    defaultValues,
    hasSettedDates,
    mainDateRange,
    savePeriodSelected,
    resetComparisonDates,
    resetDateFilters,
    saveComparisonDateRange,
    saveMainDateRange,
  ])

  return (
    <DateRangePickerFilterContext.Provider value={contextValue}>
      {children(contextValue)}
    </DateRangePickerFilterContext.Provider>
  )
}
