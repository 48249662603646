import { Tooltip } from '@mui/material'
import { IconInfoCircle } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useKnowledgeBasePageQuery } from '~/hooks/queries'
import { ExternalLink } from '../ExternalLink'
import { RichTextContent } from './RichTextContent'
import type { DocsTooltipIconProps } from './types'

export const KnowledgeBaseTooltipIcon = (props: DocsTooltipIconProps) => {
  const { pageContentId, pageUrl, concept, iconProps, ...rest } = props

  const { t } = useTranslation()

  const query = useKnowledgeBasePageQuery({ content_id: pageContentId })

  const tooltipContent = (
    <div className="flex flex-col">
      {query.isLoading && `${t('knowledgeBase.loadingDocumentation')}...`}
      {query.isError && `${t('knowledgeBase.error')}`}
      {query.isSuccess && <RichTextContent richText={query.data?.summary} />}

      <ExternalLink
        href={pageUrl}
        className="mt-2 text-azure"
        title={t('knowledgeBase.openDocsPageInNewTabMessage', { concept })}
      >
        {t(query.isSuccess ? 'action.seeMore' : 'knowledgeBase.openToDocsPage')}
      </ExternalLink>
    </div>
  )

  return (
    <Tooltip title={tooltipContent} arrow {...rest}>
      <IconInfoCircle {...iconProps} className="text-night-600" />
    </Tooltip>
  )
}
