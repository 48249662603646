import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconMicrosoft } from '~/assets/icons'
import { IconGoogle } from '~/assets/icons/IconGoogle'
import { Button } from '~/components/UI/Button'
import { Loader } from '~/components/UI/Loader'
import { Select } from '~/components/UI/Select'
import { useTenantsQuery } from '~/hooks/queries/useTenantsQuery'
import { signInWithGooglePopup, signInWithMicrosoftPopup, signOut } from '~/lib/firebase'
import { useAuthContext } from '~/providers/AuthProvider'
import { saveTenantCurrencyInLocalStorage } from '~/utils/tenant'

export const RegisterForm = () => {
  const { t } = useTranslation(['common', 'login'])

  const {
    user,
    isAuthenticated,
    isAuthenticatedWithTenant,
    tenantId,
    setTenantIdToLoad,
    isAuthenticatingWithTenant,
  } = useAuthContext()

  const [tempTenantId, setTempTenantId] = useState('')

  const shouldFetchTenants = isAuthenticated && !isAuthenticatedWithTenant
  const tenantsQuery = useTenantsQuery(shouldFetchTenants)

  const handleClickSignUpButton = async () => {
    await signInWithGooglePopup()
  }

  const handleClickSignUpButtonMicrosoft = async () => {
    await signInWithMicrosoftPopup()
  }

  const handleChangeTenant = (tenantId: string) => {
    setTempTenantId(tenantId)
  }

  const handleClickContinueBtn = () => {
    const tenantCurrency = tenantsQuery.data?.tenants.find(
      (tenant) => tenant.id === tempTenantId,
    )?.currency

    saveTenantCurrencyInLocalStorage(tenantCurrency)
    setTenantIdToLoad(tempTenantId)
  }

  const handleBackBtn = () => {
    signOut()
  }

  const isLoading = tenantsQuery.isLoading || isAuthenticatingWithTenant

  const googleIcon = (
    <div className="me-3 size-5 rounded-full bg-white p-0.5">
      <IconGoogle />
    </div>
  )

  const microsoftIcon = (
    <div className="h-6 w-6 p-0.5">
      <IconMicrosoft />
    </div>
  )

  const hasNoTenants = tenantsQuery.isSuccess && tenantsQuery.data.tenants.length === 0
  const hasMultipleTenants = tenantsQuery.isSuccess && tenantsQuery.data.tenants.length > 1

  useEffect(() => {
    if (isAuthenticated && hasNoTenants) {
      setTimeout(() => {
        signOut()
      }, 10000)
    }
  }, [isAuthenticated, hasNoTenants])

  useEffect(() => {
    const tenants = tenantsQuery.data?.tenants

    if (tenantsQuery.isSuccess && tenants && tenants.length > 0) {
      setTempTenantId(tenants[0].id)
    }
  }, [tenantsQuery.isSuccess, tenantsQuery.data?.tenants])

  return (
    <div className="absolute left-1/2 top-1/2 flex w-[90%] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-[20px] border border-night-200 border-opacity-40 px-5 py-14 shadow-2xl shadow-lightblue backdrop-blur-[10px] lg:w-1/2 lg:px-10 lg:py-16 xl:w-2/5">
      <div className="flex w-full flex-col items-center gap-4 md:w-[70%]">
        <h2 className="text-title-2 text-center text-night-800">
          {isAuthenticated && hasNoTenants ? t('login:dontHaveAccount') : t('login:welcome')}
        </h2>

        {!isAuthenticated ? (
          <>
            <span className="text-body-1 mb-4 text-center text-night-800">
              {t('login:singInToContinue')}
            </span>
            <div className="flex w-auto min-w-[245px] flex-col items-center justify-between gap-5">
              <Button
                className="w-full"
                onClick={handleClickSignUpButton}
                data-testid="google-login"
              >
                <div className="flex items-center justify-center">
                  {googleIcon} <span className="text-body-2">{t('login:withGoogle')}</span>
                </div>
              </Button>
              <div className="flex w-full items-center justify-center gap-5">
                <hr className="w-full text-night-100" />
                <span className="text-body-2-strong text-night-300">O</span>
                <hr className="w-full text-night-100" />
              </div>
              <Button
                className="w-full"
                variant={'ghost'}
                onClick={handleClickSignUpButtonMicrosoft}
              >
                <div className="flex items-center justify-center gap-2">
                  {microsoftIcon} {t('login:withMicrosoft')}
                </div>
              </Button>
            </div>
          </>
        ) : (
          <>
            {isLoading && <Loader />}

            {hasMultipleTenants && (
              <div className="flex w-full flex-col items-center gap-10">
                <label className="flex w-full flex-1 flex-col gap-3 md:w-3/4 xl:w-4/5">
                  <span className="text-body-2 text-night-600" data-testid="tenant-select">
                    {t('login:form.selectLabel')}
                  </span>
                  <Select
                    value={tempTenantId}
                    onChange={(_, tenantId) => handleChangeTenant(tenantId as string)}
                    slotProps={{
                      listbox: {
                        className: 'min-w-[336px]',
                      },
                    }}
                  >
                    {tenantsQuery.data?.tenants.map((tenant) => (
                      <Select.Option
                        key={tenant.id}
                        value={tenant.id}
                        data-testid={`tenant-${tenant.company.toLocaleLowerCase()}`}
                      >
                        {tenant.company}
                      </Select.Option>
                    ))}
                  </Select>
                </label>

                <div className="flex w-full justify-between md:w-3/4 xl:w-4/5">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={handleBackBtn}
                    startIcon={IconArrowLeft}
                  >
                    {t('common:action.back')}
                  </Button>
                  <Button
                    type="button"
                    disabled={!tempTenantId || tempTenantId === tenantId || isLoading}
                    onClick={handleClickContinueBtn}
                    endIcon={IconArrowRight}
                    data-testid="continue-button"
                  >
                    {t('common:action.continue')}
                  </Button>
                </div>
              </div>
            )}
            {hasNoTenants && (
              <>
                <p className="mb-10 mt-5">
                  {t('login:emailDoesntExist', { userEmail: user?.email || '' })}
                </p>
                <Button className="w-full" onClick={handleClickSignUpButton}>
                  {googleIcon} {t('login:loginAction')}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}
