import type { ZodSchema } from 'zod'
import { ZodError } from 'zod'

/**
 * Validates form values against a Zod validation schema.
 * It only validates, it does not parse the values.
 * @param values - The form values to validate.
 * @param validationSchema - The Zod validation schema to use.
 * @returns The field errors if there are any validation errors, otherwise undefined.
 */
export function validateFormValues(values: unknown, validationSchema: ZodSchema) {
  try {
    validationSchema.parse(values)
  } catch (error) {
    if (error instanceof ZodError) {
      return error.formErrors.fieldErrors
    }
  }
}
