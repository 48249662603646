import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { FILTERS_SETS } from '~/lib/queryKeys'
import type { ApiResponse, FilersSet } from '~/types/apiContracts'

const getSavedFiltersSet = async (moduleId: string) => {
  const res = (await api.get(`filters/user_filters/${moduleId}`).json()) as ApiResponse<{
    filters: FilersSet[]
  }>

  return res.data
}

export const useSavedFiltersSetsQuery = (moduleId: string) => {
  return useQuery({
    queryKey: [FILTERS_SETS, moduleId],
    queryFn: () => getSavedFiltersSet(moduleId),
  })
}
