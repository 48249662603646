import { cva } from 'class-variance-authority'

export const buttonClasses = cva(
  'inline-flex items-center justify-center rounded-xl transition-color truncate',
  {
    variants: {
      variant: {
        primary:
          'border border-azure bg-azure text-white hover:border hover:border-azure-dark focus:bg-azure focus:outline focus:outline-2 focus:outline-argentinianblue active:border-argentinianblue active:bg-argentinianblue disabled:border-columbiablue disabled:bg-columbiablue',

        secondary:
          'border border-night-200 bg-white text-night-800 hover:bg-seasalt hover:outline hover:outline-1 hover:outline-night-200 focus:border-argentinianblue focus:bg-white focus:outline focus:outline-1 focus:outline-argentinianblue active:bg-night-50 disabled:border disabled:border-night-50 disabled:bg-night-50 disabled:text-night-200 disabled:outline-none',

        ghost:
          'border border-azure bg-white text-azure hover:border-azure-dark hover:text-azure-dark hover:outline hover:outline-1 hover:outline-azure-dark focus:border-argentinianblue focus:outline focus:outline-1 focus:outline-argentinianblue active:border-argentinianblue active:outline-none disabled:border-night-50 disabled:bg-night-50 disabled:text-night-200 disabled:outline-none',

        danger:
          'border border-bittersweet bg-bittersweet text-white hover:border-bittersweet-700 hover:bg-bittersweet-600 hover:outline hover:outline-1 hover:outline-bittersweet-700 focus:border-bittersweet-700 focus:outline focus:outline-1 focus:outline-bittersweet-700 active:border-bittersweet-600 active:bg-bittersweet-600 active:outline-none disabled:border-bittersweet-50 disabled:bg-bittersweet-50 disabled:text-bittersweet-300 disabled:outline-none',

        'primary-text':
          'bg-white text-azure underline-offset-4 hover:text-azure-dark focus:outline focus:outline-2 focus:outline-argentinianblue active:text-argentinianblue disabled:text-night-200',

        'danger-text':
          'bg-white text-bittersweet underline-offset-4 hover:text-bittersweet-700 focus:outline focus:outline-2 focus:outline-bittersweet-700 active:text-bittersweet-600 disabled:text-bittersweet-300',
      },
      size: {
        unset: null,
        small: 'min-w-7 h-7',
        medium: 'min-w-9 h-9',
        large: 'min-w-11 h-11',
      },
      icon: {
        true: null,
        false: null,
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
    compoundVariants: [
      {
        icon: false,
        size: 'small',
        class: 'px-3 text-body-2-strong',
      },
      {
        icon: false,
        size: 'medium',
        class: 'px-4 text-body-2-strong',
      },
      {
        icon: false,
        size: 'large',
        class: 'px-6 text-body-1-strong',
      },
    ],
  },
)
