import { jwtDecode } from 'jwt-decode'

const { VITE_MAX_TOKEN_DURATION_IN_HOURS = 24 } = import.meta.env

/**
 * Checks if a token is too old and should be logged out. It is considered an old token if it is more than 24 hours old from being issued.
 * @param token - The token to be checked.
 * @returns A boolean indicating if the token is very old.
 */
export const checkIfTokenIsVeryOld = (token: string | null) => {
  if (!token) return false

  const decodedToken = jwtDecode(token)

  const iat = decodedToken?.iat

  if (!iat) return false

  const tokenAgeInHours = (Date.now() / 1000 - iat) / 3600

  return tokenAgeInHours > +VITE_MAX_TOKEN_DURATION_IN_HOURS
}
