import { useCallback, useMemo } from 'react'
import { proccessDateFilter, processFilter } from './helpers'
import type { Filter, FiltersContextModuleValue, PreProcessedDateFilters } from './types'
import { useFiltersContext } from './useFiltersContext'

export const useFilters = (moduleId: string) => {
  const context = useFiltersContext()

  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider')
  }

  const {
    groupedFilters,
    addFilter: addFilterToGroup,
    addFilters: addFiltersToGroup,
    updateFilter: updateFilterInGroup,
    upsertFilters: upsertFiltersInGroup,
    removeFilter: removeFilterFromGroup,
    removeFilters: removeFiltersFromGroup,
    clearFilters: clearGroupFilters,
  } = context

  const filtersById = groupedFilters[moduleId]

  const addFilter = useCallback(
    (filter: Filter) => {
      addFilterToGroup(moduleId, filter)
    },
    [addFilterToGroup, moduleId],
  )

  const addFilters = useCallback(
    (filters: Filter[]) => {
      addFiltersToGroup(moduleId, filters)
    },
    [addFiltersToGroup, moduleId],
  )

  const upsertFilters = useCallback(
    (filters: Filter[]) => {
      upsertFiltersInGroup(moduleId, filters)
    },
    [upsertFiltersInGroup, moduleId],
  )

  const updateFilter = useCallback(
    (filter: Filter) => {
      updateFilterInGroup(moduleId, filter)
    },
    [updateFilterInGroup, moduleId],
  )

  const removeFilter = useCallback(
    (filterId: Filter['id']) => {
      removeFilterFromGroup(moduleId, filterId)
    },
    [removeFilterFromGroup, moduleId],
  )

  const removeFilters = useCallback(
    (filtersIds: string[]) => {
      removeFiltersFromGroup(moduleId, filtersIds)
    },
    [removeFiltersFromGroup, moduleId],
  )

  const clearFilters = useCallback(() => {
    clearGroupFilters(moduleId)
  }, [clearGroupFilters, moduleId])

  const contextValue: FiltersContextModuleValue = useMemo(() => {
    const filters = filtersById ? Object.values(filtersById) : []
    const dateFilters = []
    const basicFilters = []

    for (const filter of filters) {
      if (filter.valueType === 'date') {
        dateFilters.push(filter)
      } else {
        basicFilters.push(filter)
      }
    }

    const processedFilters = basicFilters.map(processFilter)
    const processedDateFilters = proccessDateFilter(dateFilters as PreProcessedDateFilters)

    return {
      filtersById: filtersById || {},
      filters,
      dateFilters,
      basicFilters,
      addFilter,
      addFilters,
      updateFilter,
      removeFilter,
      removeFilters,
      processedFilters,
      clearFilters,
      upsertFilters,
      processedDateFilters,
    } satisfies FiltersContextModuleValue
  }, [
    filtersById,
    addFilter,
    addFilters,
    updateFilter,
    removeFilter,
    removeFilters,
    clearFilters,
    upsertFilters,
  ])

  return contextValue
}
