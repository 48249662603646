import { getTenantCurrencyFromLocalStorage } from './tenant'

const locale = 'es-CO' // TODO: this locale should be get from the user's settings, like regional config

export const integerFormatter = new Intl.NumberFormat(locale, {
  maximumFractionDigits: 0,
})

export function formatInteger(value: number) {
  return integerFormatter.format(value)
}

export const decimalsFormatter = new Intl.NumberFormat(locale, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function formatDecimal(value: number) {
  return decimalsFormatter.format(value)
}

const currencyFormatters: Record<string, Intl.NumberFormat> = {}

export function formatCurrency(value: number) {
  const currency = getTenantCurrencyFromLocalStorage()

  if (!(currency in currencyFormatters)) {
    currencyFormatters[currency] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol',
    })
  }

  // This might be changed in the future
  let formattedValue = currencyFormatters[currency].format(value)

  if (formattedValue.endsWith('$')) {
    formattedValue = '$' + formattedValue.slice(0, -1)
  }

  return formattedValue
}

export const parseFormattedNumber = (strValue: string, fallbackValue: number, decimals = 0) => {
  const parseString = strValue.replace(/\D/g, '')
  let parsedNumber = parseString ? Number(parseString) : 0
  if (parsedNumber > Number.MAX_SAFE_INTEGER) return fallbackValue

  if (decimals === 2) parsedNumber /= 100

  return parsedNumber
}

export function safeNumber(value: number | null | undefined): number {
  return value || 0
}

export function formatPercentage(value: number): string {
  return `${decimalsFormatter.format(value)}%`
}
