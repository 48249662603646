import clsx from 'clsx'
import { useField } from 'formik'
import { Checkbox } from '../Checkbox'
import type { CheckboxControlledProps } from './types'

export const CheckboxControlled = ({ name, value, ...props }: CheckboxControlledProps) => {
  const [field, { error, touched }] = useField({
    name,
    value,
    type: 'checkbox',
  })

  return (
    <Checkbox
      {...props}
      {...field}
      labelClassName={clsx(props.labelClassName, { 'text-bittersweet': !!error && touched })}
    />
  )
}
