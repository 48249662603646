import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from '~/components/UI/Toast'
import { api } from '~/lib/api'
import { queryClient } from '~/lib/queryClient'
import { FILTERS_SETS, FILTERS_SETS_DELETE } from '~/lib/queryKeys'
import type { ApiResponse, FilersSet } from '~/types/apiContracts'

const deleteFiltersSet = async (moduleId: string, filtersSetName: string) => {
  const res = (await api
    .delete(`filters/user_filters/${moduleId}/${filtersSetName}`)
    // TODO: update the response type when the API is ready
    .json()) as ApiResponse<unknown>

  return res.data
}

export const useFiltersSetDeleteMutation = () => {
  const { t } = useTranslation(['filter'])

  return useMutation({
    mutationKey: [FILTERS_SETS_DELETE],
    mutationFn: ({ moduleId, filtersSetName }: { moduleId: string; filtersSetName: string }) => {
      return deleteFiltersSet(moduleId, filtersSetName)
    },
    onSuccess: (_data, variables) => {
      // update FILTERS_SETS query without deleted filters set
      toast({
        status: 'success',
        content: t('filter:status.successDelete', { filterName: variables?.filtersSetName }),
      })

      queryClient.setQueryData(
        [FILTERS_SETS, variables.moduleId],
        (old: { filters: FilersSet[] }) => {
          return {
            filters: old.filters
              ? old.filters.filter((filter) => filter.name !== variables.filtersSetName)
              : [],
          }
        },
      )

      // invalidate the data after 5s, the API is taking time to update the data
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: [FILTERS_SETS, variables.moduleId] }),
        5000,
      )
    },
  })
}
