import { useField } from 'formik'
import type { FormatInputType } from '~/types/shared'
import { formatInteger, parseFormattedNumber } from '~/utils/number'

export const useFieldControlled = (name: string, format: FormatInputType = 'text') => {
  const [{ onChange: _, ...field }, meta, helpers] = useField(name)

  const formatPercentage = (value: number) => String(Math.min(100, Math.max(0, value)))
  const formatThousands = (value: number) => formatInteger(value)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    if (format === 'text') {
      helpers.setValue(inputValue)
      return
    }

    const numberValue = parseFormattedNumber(inputValue, 0)

    if (format === 'number') {
      helpers.setValue(numberValue)
      return
    }

    const formatters: Partial<Record<FormatInputType, (value: number) => string>> = {
      percentage: formatPercentage,
      thousands: formatThousands,
    }

    const formattedValue = formatters[format]?.(numberValue)

    helpers.setValue(formattedValue)
  }

  return [{ onChange, ...field }, meta, helpers] as const
}
