import type { DataTableExportDialogSectionProps } from './types'

export const DataTableExportDialogSection = (props: DataTableExportDialogSectionProps) => {
  return (
    <div className="@container">
      <h3 id={props.titleId} className="text-subtitle-2-strong mb-4">
        {props.title}
      </h3>
      {props.children}
    </div>
  )
}
