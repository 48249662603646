import * as d3 from 'd3'
import { useEffect, useRef } from 'react'
import { colors } from '~/theme/colors'
import type { OptionalClassName } from '~/types'

export const LoginBackground = ({ className }: OptionalClassName) => {
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (svgRef.current) {
      const path = d3.select(svgRef.current).select<SVGPathElement>('#line')
      const totalLength = path?.node()?.getTotalLength()

      const circle = d3.select(svgRef.current).select<SVGPathElement>('#dot')

      const circleInitialPoint = circle?.node()?.getBBox()

      const pathEl = path.node()

      if (!path || !totalLength || !circle || !circleInitialPoint || !pathEl) return

      const lineTotalLength = pathEl.getTotalLength()
      const animate = () => {
        circle
          .transition()
          .duration(15000)
          .ease(d3.easeLinear)
          .attrTween('transform', () => {
            return (t) => {
              const point = pathEl.getPointAtLength(t * lineTotalLength)

              return `translate(${point.x - circleInitialPoint.x - 10}, ${
                point.y - circleInitialPoint.y - 10
              })`
            }
          })
          .on('end', () => setTimeout(animate, 2000))
      }

      animate()
    }
  }, [])

  return (
    <svg
      className={className}
      viewBox="0 0 1440 642"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <g>
        <path
          d="M157.516 328.503L5.01835 416.117C1.914 417.9 0 421.207 0 424.788V632C0 637.523 4.47711 642 9.99996 642H1430C1435.52 642 1440 637.523 1480 632V2L1339.68 67.2922C1338.84 67.837 1338.09 68.5041 1337.45 69.2714L1245.11 180.077C1244.35 180.987 1243.43 181.755 1242.41 182.345L1098.71 264.919C1096.97 265.92 1094.97 266.373 1092.96 266.219L820.538 245.2C817.793 244.988 815.083 245.917 813.045 247.769L729.989 323.225C728.148 324.897 725.751 325.824 723.264 325.824H562.929C560.771 325.824 558.67 326.522 556.941 327.815L438.545 416.349C436.287 418.038 433.422 418.694 430.655 418.158L294.353 391.762C293.455 391.588 292.585 391.292 291.768 390.882L166.984 328.236C163.983 326.73 160.427 326.83 157.516 328.503Z"
          fill="url(#paint0_linear_200_452)"
          fillOpacity="0.5"
        />
        <path
          id="line"
          d="M-1 419.5L157.489 328.294C160.415 326.61 163.992 326.516 167.003 328.044L292.766 391.874C293.585 392.289 294.457 392.59 295.358 392.768L429.135 419.139C431.914 419.687 434.795 419.031 437.062 417.334L556.94 327.59C558.669 326.295 560.772 325.595 562.933 325.595H723.267C725.752 325.595 728.148 324.67 729.988 322.999L813.046 247.595C815.083 245.745 817.793 244.817 820.536 245.028L1092.97 266.033C1094.97 266.187 1096.97 265.735 1098.71 264.734L1242.41 182.218C1243.43 181.628 1244.35 180.86 1245.11 179.951L1337.45 69.2243C1338.09 68.4574 1338.84 67.7906 1339.68 67.2461L1480 2"
          stroke="#314CFF"
          strokeOpacity="0.3"
          strokeWidth="3"
        />
        <g id="dot" filter="url(#filter0_d_200_452)">
          <circle id="purple_dot" cx="1093.5" cy="266.5" r="10" fill="#314CFF" />
          <circle id="radio_dot" cx="1093.5" cy="266.5" r="10" stroke="white" strokeWidth="4" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_200_452"
          x="1061.9"
          y="233.582"
          width="65.8359"
          height="65.8359"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4.61051"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_200_452"
          />
          <feOffset dx="1.31729" />
          <feGaussianBlur stdDeviation="4.61051" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.192157 0 0 0 0 0.298039 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_200_452" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_200_452"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_200_452"
          x1="720"
          y1="79.8674"
          x2="806"
          y2="547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9795FF" />
          <stop offset="1" stopColor={colors.ghostwhite} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
