import { useLocation } from '@tanstack/react-router'
import { getModuleIdFromPathname } from '~/utils/getModuleId'

/**
 * Generate a module id based on the url.
 */
export const useModuleId = () => {
  const pathname = useLocation({ select: (state) => state.pathname })
  const moduleId = getModuleIdFromPathname(pathname)

  return moduleId
}
