import { getTranslatedWeekDayCodesMap } from '../builtInTranslations'

export type WeekDayCode = keyof typeof weekDayCodesMap

export const weekDayCodesMap = {
  su: 'sunday',
  m: 'monday',
  t: 'tuesday',
  w: 'wednesday',
  th: 'thursday',
  f: 'friday',
  s: 'saturday',
} as const

export const weekDayCodes = Object.keys(weekDayCodesMap) as WeekDayCode[]

export function parseWeekDayCodesFromString(value: string): WeekDayCode[] {
  if (!value) return []

  return value.split(',') as WeekDayCode[]
}

export function sortWeekDayCodes(weekDays: WeekDayCode[]): WeekDayCode[] {
  return weekDays.toSorted((a, b) => {
    return weekDayCodes.indexOf(a) - weekDayCodes.indexOf(b)
  })
}

export function formatWeekDayCodes(weekDays: WeekDayCode[]): string {
  if (!weekDays || weekDays.length === 0) return ''

  const translatedWeekDayCodesMap = getTranslatedWeekDayCodesMap()
  const sortedWeekDays = sortWeekDayCodes(weekDays)

  return sortedWeekDays.map((code) => translatedWeekDayCodesMap[code]).join(', ')
}

/**
 * Formats the week day codes from a string value.
 * The week day codes are sorted and translated.
 */
export function formatWeekDayCodesFromString(value: string): string {
  return formatWeekDayCodes(parseWeekDayCodesFromString(value))
}
