import clsx from 'clsx/lite'
import type { HTMLAttributes, ReactNode } from 'react'
import type { OptionalClassName } from '~/types'
import { ModalCloseButton } from './ModalCloseButton'

export interface ModalHeaderProps extends OptionalClassName {
  showBorder?: boolean
  title?: string
  titleProps?: HTMLAttributes<HTMLHeadingElement>
  customContent?: ReactNode
  onClose?: () => void
}

export const ModalHeader = ({
  showBorder = true,
  title,
  titleProps,
  customContent,
  onClose,
  className,
  ...rest
}: ModalHeaderProps) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-2',
        showBorder && 'border-b border-b-night-100',
        className,
      )}
      {...rest}
    >
      {title && (
        <h2
          {...titleProps}
          className={clsx('text-subtitle-1 mr-4 capitalize', titleProps?.className)}
        >
          {title}
        </h2>
      )}
      {customContent}
      {onClose && <ModalCloseButton className="ms-auto self-center" onClick={onClose} />}
    </div>
  )
}
