import { IconFilter } from '@tabler/icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFiltersOptionsQuery } from '~/hooks/queries/useFiltersOptionsQuery'
import { useFilters } from '~/providers/FiltersProvider'
import { FiltersButton } from '../FiltersButton'
import { MainFiltersModal } from '../MainFiltersModal'
import type { MainFiltersProps } from './types'

export const MainFilters = (props: MainFiltersProps) => {
  const { t } = useTranslation()
  const { basicFilters } = useFilters(props.moduleId)

  const filtersOptionsQuery = useFiltersOptionsQuery(props.moduleId)

  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const filtersCount = basicFilters.length

  return (
    <>
      <FiltersButton
        onClick={openModal}
        title={t('showFilters')}
        badgeContent={filtersOptionsQuery.isLoading ? undefined : filtersCount}
        loading={filtersOptionsQuery.isLoading}
      >
        <IconFilter size={25} />
      </FiltersButton>
      <MainFiltersModal
        moduleId={props.moduleId}
        open={open}
        onClose={closeModal}
        confirmFiltersModalSubmit={props.confirmFiltersModalSubmit}
      />
    </>
  )
}
