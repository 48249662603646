import { Button as BaseButton } from '@mui/base/Button'
import clsx from 'clsx/lite'
import { Badge } from '../UI/Badge'
import type { FiltersButtonProps } from './types'

export const FiltersButton = (props: FiltersButtonProps) => {
  const button = (
    <BaseButton
      className={clsx(
        'flex h-11 min-w-12 items-center justify-center rounded-xl border border-night-200 bg-white disabled:bg-seasalt',
        props.loading && 'animate-pulse cursor-wait',
      )}
      disabled={props.loading || props.disabled}
      data-testid="main-filters-button"
      onClick={() => {
        if (props.disabled || props.loading) return

        props.onClick()
      }}
      title={props.title}
    >
      {props.children}
    </BaseButton>
  )

  if (!props.badgeContent) return button

  return (
    <Badge badgeContent={props.badgeContent} color="primary">
      {button}
    </Badge>
  )
}
