import { z } from 'zod'
import i18n from '~/lib/i18n'

export const demandPromotionSearch = z.object({
  campaign: z
    .object({
      id: z.string(),
      name: z.string(),
      promotionName: z.string(),
      comments: z.string().optional(),
    })
    .optional(),
})

export type DemandPromotionSearch = z.infer<typeof demandPromotionSearch>

export const getCampaignDetailsFromParams = (campaign: DemandPromotionSearch['campaign']) => {
  if (!campaign) {
    return []
  }

  const pageDetails = [
    [i18n.t('planning:promotionalCampaigns.name'), campaign.name],
    [i18n.t('planning:promotions.name'), campaign.promotionName],
  ]

  if (campaign?.comments) {
    pageDetails.push([i18n.t('common:comments'), campaign.comments])
  }

  return pageDetails
}
