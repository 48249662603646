import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { TENANTS_MODULES } from '~/lib/queryKeys'
import type { ApiResponse, TenantsModulesResponse } from '~/types/apiContracts'

async function fetchTenantModules(tenandId: string) {
  const res = (await api
    .get(`tenants/tenants/${tenandId}/modules`)
    .json()) as ApiResponse<TenantsModulesResponse>

  return res.data
}

export function useTenantModulesQuery(tenantId: string | null, isAuthenticatedWithTenant: boolean) {
  return useQuery({
    queryKey: [TENANTS_MODULES, tenantId],
    queryFn: () => fetchTenantModules(tenantId!),
    enabled: !!tenantId && isAuthenticatedWithTenant,
  })
}
