import { type User } from 'firebase/auth'
import ky from 'ky'
import type { Permission } from '~/types/apiContracts'
import type { RoutePath } from '~/types/shared'

/**
 * Unlike other providers supported by Firebase Auth,
 * Microsoft does not provide a photo URL and instead,
 * the binary data for a profile photo has to be requested via Microsoft Graph API.
 */
export async function fetchImageMicrosoft(token: string) {
  const res = await ky
    .get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .blob()

  return URL.createObjectURL(res)
}

export function checkModulePermission(path: RoutePath, permissions: Permission[] | null) {
  if (!permissions) {
    return false
  }

  const [moduleName, submoduleName] = path.split('/').slice(1)

  if (!submoduleName) {
    const modulePermissions = permissions.filter(
      (permission) => permission.code.split('.')[0] == moduleName && permission.active,
    )
    return modulePermissions.length > 0
  }

  if (submoduleName) {
    const modulePermissions = permissions.filter(
      (permission) => permission.code == `${moduleName}.${submoduleName}` && permission.active,
    )
    return modulePermissions.length > 0
  }

  return false
}

export const syncUserDetails = async (user: User) => {
  // user details come from the sign in with google popup,
  // but no on page refresh, so we store them in localStorage
  // to persist them
  const storedDisplayName = localStorage.getItem('user.displayName')
  const storedEmail = localStorage.getItem('user.email')
  const storedPhotoURL = localStorage.getItem('user.photoURL')
  const storedUserToken = localStorage.getItem('user.oauthToken')

  let displayName = user.displayName
  let email = user.email
  let photoURL = user.photoURL

  if (!photoURL && !storedPhotoURL && storedUserToken) {
    try {
      photoURL = await fetchImageMicrosoft(storedUserToken)
    } catch (error) {
      photoURL = null
    }
  }

  if (displayName != null && storedDisplayName !== displayName) {
    localStorage.setItem('user.displayName', displayName)
  }

  if (email != null && storedEmail !== email) {
    localStorage.setItem('user.email', email)
  }

  if (photoURL != null && storedPhotoURL !== photoURL) {
    localStorage.setItem('user.photoURL', photoURL)
  }

  displayName ??= storedDisplayName
  email ??= storedEmail
  photoURL ??= storedPhotoURL

  return { ...user, displayName, email, photoURL }
}
