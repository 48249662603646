import { Select as BaseSelect } from '@mui/base/Select'
import { resolveComponentProps } from '@mui/base/utils'
import clsx from 'clsx/lite'
import { forwardRef, useRef } from 'react'
import { SelectButton } from '../SelectButton'
import type { SelectProps } from './types'

export const Select = forwardRef(
  // We just copied the generic types from SelectOwnProps to
  function Select<OptionValue extends NonNullable<unknown>, Multiple extends boolean>(
    { error, size = 'medium', ...props }: SelectProps<OptionValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) {
    const rooRef = useRef<HTMLButtonElement>(null)

    return (
      <BaseSelect
        ref={ref}
        {...props}
        slots={{
          root: SelectButton,
          ...props.slots,
        }}
        className={clsx(props.className)}
        slotProps={{
          ...props.slotProps,
          root: (ownerState) => {
            const resolvedSlotProps = resolveComponentProps(props.slotProps?.root, ownerState)

            return {
              ...resolvedSlotProps,
              ref: rooRef,
              className: clsx(
                'relative inline-flex items-center truncate whitespace-nowrap rounded-xl px-3 transition-all',
                size === 'small' && 'h-7 min-w-20',
                size === 'medium' && 'h-9',
                size === 'large' && 'h-11',
                ownerState.disabled
                  ? 'bg-night-50 text-night-300 !outline-none'
                  : 'bg-white outline',
                ownerState.value ? 'text-night-600' : 'text-night-400',
                ownerState.focusVisible ? 'outline-0' : 'outline outline-1 outline-azure',
                error ? 'outline-bittersweet' : 'outline-night-200',
                resolvedSlotProps?.className,
              ),
            }
          },
          listbox: (ownerState) => {
            const resolvedSlotProps = resolveComponentProps(props?.slotProps?.listbox, ownerState)

            return {
              ...resolvedSlotProps,
              className: clsx(
                'my-1 flex max-h-80 flex-col gap-1 overflow-auto bg-white p-1.5 font-sans text-sm text-night-800 shadow shadow-night-200 outline-0',
                resolvedSlotProps?.className,
              ),
              style: {
                minWidth: rooRef.current ? rooRef.current.offsetWidth : 'auto',
                // to make the listbox have the same width as the root element
              },
            }
          },
          popup: (ownerState) => {
            const resolvedSlotProps = resolveComponentProps(props.slotProps?.popup, ownerState)

            return {
              ...resolvedSlotProps,
              className: clsx('z-[9999]', resolvedSlotProps?.className),
            }
          },
        }}
      />
    )
  },
)
